import React, {useLayoutEffect} from 'react';
import {Button, Col, Container, Form, ModalFooter, Row, Spinner} from "react-bootstrap";
import {FaAddressCard, FaCalculator, FaSave, FaWindowClose, FaPencilAlt} from "react-icons/fa";
import {useKeycloak} from "@react-keycloak/web";
import {observer} from "mobx-react";
import Store from "../stores/CustomerStore";
import Modal from "react-modal";
import {Link} from "react-router-dom";
import MuiButton from "@mui/material/Button";
import MuiBackdrop from "@mui/material/Backdrop";
import MuiFade from "@mui/material/Fade";
import MuiBox from "@mui/material/Box";
import MuiModal from "@mui/material/Modal";

import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import Tabs from 'react-bootstrap/Tabs'
import { IconContext } from "react-icons";

const Address = observer((props: any) => {

    const {isLoaded, address, fetchAddress, changeAddress, countries, fetchCountries, deliveries, deliveryList, fetchAllDeliveries, regions,
        cities,
        points,
        calculation,
        fetchRegions,
        fetchCities,
        fetchPoints,
        calculateDelivery} = Store
    const {keycloak} = useKeycloak()

    // Russian
    const zipCode = React.createRef<HTMLInputElement>();
    const state = React.createRef<HTMLInputElement>();
    const city = React.createRef<HTMLInputElement>();
    const street = React.createRef<HTMLInputElement>();
    const recipient = React.createRef<HTMLInputElement>();
    const phone = React.createRef<HTMLInputElement>();


    // CIS
    const cisCountry = React.createRef<HTMLSelectElement>();
    const cisFullAddress = React.createRef<HTMLInputElement>();
    const cisRecipient = React.createRef<HTMLInputElement>();
    const cisAdditionalInfo = React.createRef<HTMLInputElement>();

    // CDEK
    const cdekToLocation = React.createRef<HTMLInputElement>();
    const cdekTariffCode = React.createRef<HTMLInputElement>();
    const cdekFullAddress = React.createRef<HTMLInputElement>();
    const cdekPhone = React.createRef<HTMLInputElement>();
    const cdekRecipient = React.createRef<HTMLInputElement>();


    const delivery = React.createRef<HTMLSelectElement>();

    // CDEK calculation modal
    const cdekRegion = React.createRef<HTMLSelectElement>();
    const cdekCity = React.createRef<HTMLSelectElement>();
    const cdekPoint = React.createRef<HTMLSelectElement>();
    const cdekTariff = React.createRef<HTMLSelectElement>();

    // Выбранный пункт доставки
    const [cdekPointCode, setCdekPointCode] = React.useState('');
    const [cdekTariffCodeValue, setCdekTariffCodeValue] = React.useState('');
    const [cdekPointAddress, setCdekPointAddress] = React.useState({});



    const [modalAddressIsOpen, setModalAddressIsOpen] = React.useState(false);
    const [selectedDelivery, setSelectedDelivery] = React.useState('');
    const [cdekModalIsOpen, setIsOpenCdekModal] = React.useState(false);

    // табы
    const [key, setKey] = React.useState('russian_post');


    function openCdekModal() {
        // closeOneClickOrderModal();
        fetchRegions();
        setIsOpenCdekModal(true);
    }

    function closeCdekModal() {
        setIsOpenCdekModal(false);
    }


    function openAddressModal() {
        setModalAddressIsOpen(true);
    }

    function closeAddressModal() {
        setModalAddressIsOpen(false);
    }

    function getDeliveryType() {
        if (!!address && !!address['deliveryAddress']) {
            return address['deliveryAddress']['type']
        } else {
            return 'russian_post'
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();

        const address = {
            deliveryAddress: {
                type: key,
                zipCode: zipCode.current?.value,
                state: state.current?.value,
                city: city.current?.value,
                street: street.current?.value,
                recipient: recipient.current?.value,
                phone: phone.current?.value,
            }
        }

        changeAddress(keycloak.token, address);
        closeAddressModal()
    };

    const handleSubmitCis = (e: any) => {
        e.preventDefault();

        const address = {
            deliveryAddress: {
                type: key,
                country: cisCountry.current?.value,
                fullAddress: cisFullAddress.current?.value,
                recipient: cisRecipient.current?.value,
                additionalInfo: cisAdditionalInfo.current?.value,
            }
        }

        changeAddress(keycloak.token, address);
        closeAddressModal()
    };

    const handleSubmitCdek = (e: any) => {
        e.preventDefault();
        const address = {
            deliveryAddress: {
                type: key,
                toLocation: cdekCity.current?.value,
                tariffCode: cdekTariff.current?.value,
                fullAddress: cdekPoint.current?.value,
                recipient: cdekRecipient.current?.value,
                phone: cdekPhone.current?.value,
            }
        }
        changeAddress(keycloak.token, address);
        closeAddressModal()
    };

    useLayoutEffect(() => {
        // "This only happens ONCE.  But it still happens AFTER the initial render."
        fetchAddress(keycloak.token);
        setKey(getDeliveryType());
        fetchCountries();
        fetchAllDeliveries();
    }, []);

    const handleCdekCalculateSubmit = (e: any) => {
        e.preventDefault();
        let code = cdekPoint.current?.value;
        setCdekPointCode('' + code);
        let tariff = cdekTariff.current?.value;
        setCdekTariffCodeValue('' + tariff);
        let address = points.find(p => p['location']['city_code'] === code);
        console.log("test: ", JSON.stringify(address));
        const request = {
            date: new Date().toISOString(),
            tariffCode: tariff,
            toLocation: code,
            currency: "RUB"
        };
        console.log("cdekPointCode: " + cdekPointCode)
        calculateDelivery(request)
    };

    const style = {
        position: 'absolute' as 'absolute',
        top: '10%',
        left: '10%',
        // transform: 'translate(-50%, -50%)',
        height: '80%',
        display: 'block',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'scroll'
    };
    const redFont = {
        'color': 'IndianRed',
        'font-weight': 'bold'
    };

    return (
        <>
            <Container style={{marginTop: 100}}>
                <Row>
                    <Col>
                        <h1><FaAddressCard/> Адрес доставки</h1>
                    </Col>
                </Row>

                {isLoaded && <Spinner animation="grow" variant="info"/>}

                {!isLoaded && (

                    <>

                        <Tabs
                            id="address-tab-view"
                            activeKey={getDeliveryType()}
                            className="mb-3"
                        >
                            <Tab eventKey="russian_post" title="Почта России" disabled>

                                < Row className="mb-3 p-2 bg-light shadow-sm">

                                    <Col className="my-auto col-12">

                                        {!!address && !!address['deliveryAddress'] && (<dl className="row">

                                            <dt className="col-sm-3">Индекс</dt>
                                            <dd className="col-sm-9">{address['deliveryAddress']['zipCode']}</dd>

                                            <dt className="col-sm-3">Область/район</dt>
                                            <dd className="col-sm-9">{address['deliveryAddress']['state']}</dd>

                                            <dt className="col-sm-3">Город/Населенный пункт</dt>
                                            <dd className="col-sm-9">{address['deliveryAddress']['city']}</dd>

                                            <dt className="col-sm-3">Улица</dt>
                                            <dd className="col-sm-9">{address['deliveryAddress']['street']}</dd>

                                            <dt className="col-sm-3">Получатель</dt>
                                            <dd className="col-sm-9">{address['deliveryAddress']['recipient']}</dd>

                                            <dt className="col-sm-3">Способ связи</dt>
                                            <dd className="col-sm-9">{address['deliveryAddress']['phone']}</dd>

                                        </dl>)}

                                    </Col>

                                </Row>

                            </Tab>

                            <Tab eventKey="cis_post" title="Почта СНГ (CIS)" disabled>

                                < Row className="mb-3 p-2 bg-light shadow-sm">

                                    <Col className="my-auto col-12">

                                        {!!address && !!address['deliveryAddress'] && (<dl className="row">

                                            <dt className="col-sm-3">Страна</dt>
                                            <dd className="col-sm-9">{address['deliveryAddress']['country']}</dd>

                                            <dt className="col-sm-3">Полный адрес</dt>
                                            <dd className="col-sm-9">{address['deliveryAddress']['fullAddress']}</dd>

                                            <dt className="col-sm-3">Получатель</dt>
                                            <dd className="col-sm-9">{address['deliveryAddress']['recipient']}</dd>

                                            <dt className="col-sm-3">Дополнительная информация</dt>
                                            <dd className="col-sm-9">{address['deliveryAddress']['additionalInfo']}</dd>

                                        </dl>)}

                                    </Col>

                                </Row>

                            </Tab>
                            <Tab eventKey="int_post" title="Международная почта (International)" disabled>
                            </Tab>
                            <Tab eventKey="cdek" title="CDEK" disabled>
                                < Row className="mb-3 p-2 bg-light shadow-sm">

                                    <Col className="my-auto col-12">

                                        {!!address && !!address['deliveryAddress'] && (<dl className="row">

                                            <dt className="col-sm-3">Пункт назначения CDEK (номер)</dt>
                                            <dd className="col-sm-9">{address['deliveryAddress']['toLocation']}</dd>

                                            <dt className="col-sm-3">Код тарифа</dt>
                                            <dd className="col-sm-9">{address['deliveryAddress']['tariffCode']}</dd>

                                            <dt className="col-sm-3">Полный адрес</dt>
                                            <dd className="col-sm-9">{address['deliveryAddress']['fullAddress']}</dd>

                                            <dt className="col-sm-3">Получатель</dt>
                                            <dd className="col-sm-9">{address['deliveryAddress']['recipient']}</dd>

                                            <dt className="col-sm-3">Телефон</dt>
                                            <dd className="col-sm-9">{address['deliveryAddress']['phone']}</dd>

                                        </dl>)}

                                    </Col>

                                </Row>
                            </Tab>
                        </Tabs>

                    </>)}
                <Row className="mb-3 p-2 bg-light shadow-sm">
                    <Col className="my-auto col-12 text-right">
                        <Button
                            variant="outline-info"
                            onClick={() => {
                                openAddressModal()
                            }}
                        >
                            Изменить
                        </Button>
                    </Col>
                </Row>

            </Container>

            <Modal
                isOpen={modalAddressIsOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeAddressModal}
                // style={customStyles}
                contentLabel="Доступная скидка"
            >

                <div className="container">

                    <h4>
                        <span><FaAddressCard/> <ins>Адрес доставки</ins></span>
                    </h4>
                    <FaWindowClose className="float-right c-pointer mt-2" onClick={closeAddressModal}/>

                    <Tabs
                        id="address-tab-edit"
                        activeKey={key}
                        onSelect={(k:any) => {
                            setKey(k)
                            if(k === 'cdek'){
                                fetchRegions()
                            }
                        }}
                        className="mb-3"
                    >
                        <Tab eventKey="russian_post" title="Почта России">

                            <div className="row mt-3 mb-3 ml-1">
                                <div className="col-12">
                                    <Form
                                        onSubmit={handleSubmit}
                                    >
                                        {!!address && (
                                            <>
                                                <Row>
                                                    <Col md={9} xs={9} xl={9}>
                                                        <Form.Group controlId="formBasicZip">
                                                            <Form.Label>Почтовый индекс</Form.Label>
                                                            <Form.Control type="text" placeholder=""
                                                                          ref={zipCode}
                                                                          required/>
                                                            <Form.Text className="text-muted">
                                                                Введите почтовый индекс доставки. Пример: 123456
                                                            </Form.Text>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={9} xs={9} xl={9}>
                                                        <Form.Group controlId="formBasicState">
                                                            <Form.Label>Область</Form.Label>
                                                            <Form.Control type="text" placeholder=""
                                                                          ref={state}
                                                                          required/>
                                                            <Form.Text className="text-muted">
                                                                Введите область доставки. Пример: Московская область
                                                            </Form.Text>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={9} xs={9} xl={9}>
                                                        <Form.Group controlId="formBasicCity">
                                                            <Form.Label>Город / населенный пункт</Form.Label>
                                                            <Form.Control type="text" placeholder=""
                                                                          ref={city}
                                                                          required/>
                                                            <Form.Text className="text-muted">
                                                                Введите город доставки. Пример: г.Королёв
                                                            </Form.Text>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={9} xs={9} xl={9}>
                                                        <Form.Group controlId="formBasicStreet">
                                                            <Form.Label>Улица, дом, квартира</Form.Label>
                                                            <Form.Control type="text" placeholder=""
                                                                          ref={street}
                                                                          required/>
                                                            <Form.Text className="text-muted">
                                                                Введите адрес доставки. Пример: ул.Речная, д.9А, кв.123
                                                            </Form.Text>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={9} xs={9} xl={9}>
                                                        <Form.Group controlId="formBasicRecipient">
                                                            <Form.Label>Имя получателя</Form.Label>
                                                            <Form.Control type="text" placeholder=""
                                                                          ref={recipient}
                                                                          required/>
                                                            <Form.Text className="text-muted">
                                                                Введите полное имя получателя
                                                            </Form.Text>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={9} xs={9} xl={9}>
                                                        <Form.Group controlId="formBasicRecipient">
                                                            <Form.Label>Способ связи</Form.Label>
                                                            <Form.Control type="text" placeholder=""
                                                                          ref={phone}
                                                                          required/>
                                                            <Form.Text className="text-muted">
                                                                Для получения уведомлений об оплате и доставке<br/>(Например: телефон, whatsapp, telegram, viber, skype...)
                                                            </Form.Text>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={9} xs={9} xl={9}>
                                                        <Form.Group controlId="formBasicConfirmProcessPersonalData">
                                                            <Form.Check type="checkbox"
                                                                        label="Даю согласие на обработку персональных данных"
                                                                        required/>
                                                        </Form.Group>
                                                        <Link to={'/privacy'}>
                                                            Политика конфиденциальности
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            </>)}
                                        <ModalFooter>
                                            <Button variant="outline-info" type="submit">
                                                <FaSave/> Сохранить
                                            </Button>
                                        </ModalFooter>

                                    </Form>
                                </div>
                            </div>

                        </Tab>

                        <Tab eventKey="cis_post" title="Почта СНГ (CIS)" >

                            <div className="row mt-3 mb-3 ml-1">
                                <div className="col-12">
                                    <Form
                                        onSubmit={handleSubmitCis}
                                    >
                                        {!!address && (
                                            <>
                                                <Row>
                                                    <Col md={9} xs={9} xl={9}>
                                                        <Form.Switch controlId="formBasicCisCountry">
                                                            <Form.Label>Страна</Form.Label>
                                                            <Form.Control
                                                                ref={cisCountry}
                                                                as="select"
                                                                required={key === 'cis_post'}>
                                                                {countries.map((c, i) => {
                                                                    console.log(`${i}.${JSON.stringify(c)}`);
                                                                    // Return the element. Also pass key
                                                                    return (<option value={c['name']}>{c['name']}</option>)
                                                                })}
                                                            </Form.Control>
                                                            <Form.Text className="text-muted">
                                                                Выберите страну доставки из списка
                                                            </Form.Text>
                                                        </Form.Switch>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={9} xs={9} xl={9}>
                                                        <Form.Group controlId="formBasicCisFullAddress">
                                                            <Form.Label>Полный адрес</Form.Label>
                                                            <Form.Control type="text" placeholder=""
                                                                          ref={cisFullAddress}
                                                                          required={key === 'cis_post'}/>
                                                            <Form.Text className="text-muted">
                                                                Введите полный адрес доставки.
                                                            </Form.Text>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={9} xs={9} xl={9}>
                                                        <Form.Group controlId="formBasicCisRecipient">
                                                            <Form.Label>Имя получателя</Form.Label>
                                                            <Form.Control type="text" placeholder=""
                                                                          ref={cisRecipient}
                                                                          required={key === 'cis_post'}/>
                                                            <Form.Text className="text-muted">
                                                                Введите полное имя получателя
                                                            </Form.Text>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={9} xs={9} xl={9}>
                                                        <Form.Group controlId="formBasicCisAdditionalInfo">
                                                            <Form.Label>Дополнительная информация</Form.Label>
                                                            <Form.Control type="text" placeholder=""
                                                                          ref={cisAdditionalInfo}
                                                                          required={key === 'cis_post'}/>
                                                            <Form.Text className="text-muted">
                                                                Введите дополнительную информацию о доставке
                                                            </Form.Text>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={9} xs={9} xl={9}>
                                                        <Form.Group controlId="formBasicConfirmProcessPersonalData">
                                                            <Form.Check type="checkbox"
                                                                        label="Даю согласие на обработку персональных данных"
                                                                        required={key === 'cis_post'}/>
                                                        </Form.Group>
                                                        <Link to={'/privacy'}>
                                                            Политика конфиденциальности
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            </>)}
                                        <ModalFooter>
                                            <Button variant="outline-info" type="submit">
                                                <FaSave/> Сохранить
                                            </Button>
                                        </ModalFooter>

                                    </Form>
                                </div>
                            </div>


                        </Tab>

                        <Tab eventKey="cdek" title="CDEK" >


                            <div className="row mt-3 mb-3 ml-1">
                                <div className="col-12">
                                    <Form onSubmit={handleSubmitCdek}>
                                        {regions.length === 0 && <Spinner animation="grow" variant="info"/>}
                                        {regions.length > 0 && <Row>
                                            <Col md={9} xs={9} xl={9}>
                                                <Form.Switch controlId="formCdekRegion">
                                                    <Form.Label>Регион</Form.Label>
                                                    <Form.Control
                                                        ref={cdekRegion}
                                                        as="select"
                                                        onChange={(event => {
                                                            fetchCities(event.target.value)
                                                        })}
                                                        required={key === 'cdek'}>
                                                        <option value="" disabled selected>???</option>
                                                        {regions.map((r, i) => {
                                                            console.log(`${i}.${JSON.stringify(r)}`);
                                                            // Return the element. Also pass key
                                                            return (<option
                                                                value={r['region_code']}>{r['country'] + ' - ' + r['region']}</option>)
                                                        })}
                                                    </Form.Control>
                                                    <Form.Text className="text-muted">
                                                        Выберите регион доставки
                                                    </Form.Text>
                                                </Form.Switch>
                                            </Col>
                                        </Row>}

                                        {cities.length > 0 && <Row>
                                            <Col md={9} xs={9} xl={9}>
                                                <Form.Switch controlId="formCdekCity">
                                                    <Form.Label>Населенный пункт</Form.Label>
                                                    <Form.Control
                                                        ref={cdekCity}
                                                        as="select"
                                                        onChange={(event => {
                                                            fetchPoints(event.target.value)
                                                        })}
                                                        required={key === 'cdek'}>
                                                        <option value="" disabled selected>???</option>
                                                        {cities.map((c, i) => {
                                                            console.log(`${i}.${JSON.stringify(c)}`);
                                                            // Return the element. Also pass key
                                                            return (<option
                                                                value={c['code']}>{c['city']} {c['sub_region'] && ('(' + c['sub_region'] + ')')}</option>)
                                                        })}
                                                    </Form.Control>
                                                    <Form.Text className="text-muted">
                                                        Выберите населенный пункт
                                                    </Form.Text>
                                                </Form.Switch>
                                            </Col>
                                        </Row>}

                                        {points.length > 0 && <Row>
                                            <Col md={9} xs={9} xl={9}>
                                                <Form.Switch controlId="formCdekPoint">
                                                    <Form.Label>Офис CDEK</Form.Label>
                                                    <Form.Control
                                                        ref={cdekPoint}
                                                        as="select"
                                                        required={key === 'cdek'}>
                                                        <option value="" disabled selected>???</option>
                                                        {points.map((p, i) => {
                                                            console.log(`${i}.${JSON.stringify(p)}`);
                                                            // Return the element. Also pass key
                                                            return (<option
                                                                value={p['location']['address_full'] + ` (${p['code']})`}>{p['location']['address']} ({p['work_time']})</option>)
                                                        })}
                                                    </Form.Control>
                                                    <Form.Text className="text-muted">
                                                        Выберите офис CDEK
                                                    </Form.Text>
                                                </Form.Switch>
                                            </Col>
                                        </Row>}

                                        <Row>
                                            <Col md={9} xs={9} xl={9}>
                                                <Form.Switch controlId="formCdekTariff">
                                                    <Form.Label>Тариф</Form.Label>
                                                    <Form.Control
                                                        ref={cdekTariff}
                                                        as="select"
                                                        required={key === 'cdek'}>
                                                        <option value={136} selected={points.length > 0}>склад-склад
                                                        </option>
                                                        <option value={137}>склад-дверь</option>
                                                        <option value={368}>склад-постамат</option>
                                                    </Form.Control>
                                                    <Form.Text className="text-muted">
                                                        Выберите тариф
                                                    </Form.Text>
                                                </Form.Switch>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={9} xs={9} xl={9}>
                                                <Form.Group controlId="formCdekPhone">
                                                    <Form.Label>Телефон получателя</Form.Label>
                                                    <Form.Control type="text"
                                                                  ref={cdekPhone}
                                                                  required={key === 'cdek'}/>
                                                    <Form.Text className="text-muted">
                                                        Введите телефон
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={9} xs={9} xl={9}>
                                                <Form.Group controlId="formCdekRecipient">
                                                    <Form.Label>ФИО получателя</Form.Label>
                                                    <Form.Control type="text"
                                                                  ref={cdekRecipient}
                                                                  required={key === 'cdek'}/>
                                                    <Form.Text className="text-muted">
                                                        Введите полное имя получателя
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <div className="modal-footer">
                                            <MuiButton
                                                onClick={handleCdekCalculateSubmit}
                                                startIcon={<FaCalculator/>}
                                                variant="contained"
                                                disabled={points.length === 0}>
                                                Расчитать
                                            </MuiButton>
                                        </div>

                                        {!!calculation && (<>
                                            <div className="row mt-3 mb-3 ml-1">
                                                <div className="col-12">
                                                    <p>Стоимость доставки: <b>{calculation['total_sum']}</b> {calculation['currency']}</p>
                                                    <p className="small">Включая
                                                        упаковку:<span> {calculation['services'][0].code} = {calculation['services'][0].sum} {calculation.currency}</span>
                                                    </p>
                                                    <p>Минимальное время доставки (в рабочих днях): {calculation['period_min']}</p>
                                                    <p>Максимальное время доставки (в рабочих днях): {calculation['period_max']}</p>
                                                </div>
                                            </div>

                                            <Row>
                                                <Col md={9} xs={9} xl={9}>
                                                    <Form.Group controlId="formBasicConfirmProcessPersonalData">
                                                        <Form.Check type="checkbox"
                                                                    label="Даю согласие на обработку персональных данных"
                                                                    required={key === 'cdek'}/>
                                                    </Form.Group>
                                                    <Link to={'/privacy'}>
                                                        Политика конфиденциальности
                                                    </Link>
                                                </Col>
                                            </Row>

                                            <ModalFooter>
                                                <Button variant="outline-info" type={"submit"} >
                                                    <FaSave/> Сохранить
                                                </Button>
                                            </ModalFooter>
                                        </>)}

                                    </Form>
                                </div>
                            </div>

                        </Tab>

                        <Tab eventKey="int_post" title="По всему миру" >
                            {/*// TODO*/}
                        </Tab>
                    </Tabs>

                </div>


            </Modal>


            <MuiModal
                aria-labelledby="cdek-modal-title"
                aria-describedby="cdek-modal-description"
                open={cdekModalIsOpen}
                onClose={closeCdekModal}
                closeAfterTransition
                BackdropComponent={MuiBackdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >

                <MuiFade in={cdekModalIsOpen}>
                    <MuiBox sx={style}>

                        <div className="container">

                            <div className="modal-header">

                                <h4>
                                    <span><FaCalculator/> <ins>Расчет стоимости доставки <img height="20"
                                                                                              src="/cdek.png"/></ins></span>
                                </h4>
                                <FaWindowClose className="float-right c-pointer mt-2" onClick={closeCdekModal}/>

                            </div>
                            <div className="row mt-3 mb-3 ml-1">
                                <div className="col-12">
                                    <Form
                                        onSubmit={handleCdekCalculateSubmit}
                                    >

                                        {regions.length > 0 && <Row>
                                            <Col md={9} xs={9} xl={9}>
                                                <Form.Switch controlId="formCdekRegion">
                                                    <Form.Label>Регион</Form.Label>
                                                    <Form.Control
                                                        ref={cdekRegion}
                                                        as="select"
                                                        onChange={(event => {
                                                            fetchCities(event.target.value)
                                                        })}
                                                        required>
                                                        <option value="" disabled selected>???</option>
                                                        {regions.map((r, i) => {
                                                            console.log(`${i}.${JSON.stringify(r)}`);
                                                            // Return the element. Also pass key
                                                            return (<option
                                                                value={r['region_code']}>{r['country'] + ' - ' + r['region']}</option>)
                                                        })}
                                                    </Form.Control>
                                                    <Form.Text className="text-muted">
                                                        Выберите регион доставки
                                                    </Form.Text>
                                                </Form.Switch>
                                            </Col>
                                        </Row>}

                                        {cities.length > 0 && <Row>
                                            <Col md={9} xs={9} xl={9}>
                                                <Form.Switch controlId="formCdekCity">
                                                    <Form.Label>Населенный пункт</Form.Label>
                                                    <Form.Control
                                                        ref={cdekCity}
                                                        as="select"
                                                        onChange={(event => {
                                                            fetchPoints(event.target.value)
                                                        })}
                                                        required>
                                                        <option value="" disabled selected>???</option>
                                                        {cities.map((c, i) => {
                                                            console.log(`${i}.${JSON.stringify(c)}`);
                                                            // Return the element. Also pass key
                                                            return (<option
                                                                value={c['code']}>{c['city']} {c['sub_region'] && ('(' + c['sub_region'] + ')')}</option>)
                                                        })}
                                                    </Form.Control>
                                                    <Form.Text className="text-muted">
                                                        Выберите населенный пункт
                                                    </Form.Text>
                                                </Form.Switch>
                                            </Col>
                                        </Row>}

                                        {points.length > 0 && <Row>
                                            <Col md={9} xs={9} xl={9}>
                                                <Form.Switch controlId="formCdekPoint">
                                                    <Form.Label>Офис CDEK</Form.Label>
                                                    <Form.Control
                                                        ref={cdekPoint}
                                                        as="select"
                                                        required>
                                                        <option value="" disabled selected>???</option>
                                                        {points.map((p, i) => {
                                                            console.log(`${i}.${JSON.stringify(p)}`);
                                                            // Return the element. Also pass key
                                                            return (<option
                                                                value={p['location']['city_code']}>{p['location']['address']} ({p['work_time']})</option>)
                                                        })}
                                                    </Form.Control>
                                                    <Form.Text className="text-muted">
                                                        Выберите офис CDEK
                                                    </Form.Text>
                                                </Form.Switch>
                                            </Col>
                                        </Row>}

                                        <Row>
                                            <Col md={9} xs={9} xl={9}>
                                                <Form.Switch controlId="formCdekTariff">
                                                    <Form.Label>Тариф</Form.Label>
                                                    <Form.Control
                                                        ref={cdekTariff}
                                                        as="select"
                                                        required>
                                                        <option value={136} selected={points.length > 0}>склад-склад
                                                        </option>
                                                        <option value={137}>склад-дверь</option>
                                                        <option value={368}>склад-постамат</option>
                                                    </Form.Control>
                                                    <Form.Text className="text-muted">
                                                        Выберите Тариф
                                                    </Form.Text>
                                                </Form.Switch>
                                            </Col>
                                        </Row>

                                        <div className="modal-footer">
                                            <MuiButton
                                                type="submit"
                                                startIcon={<FaCalculator/>}
                                                variant="contained"
                                                disabled={points.length === 0}>
                                                Расчитать
                                            </MuiButton>
                                        </div>
                                    </Form>
                                </div>
                            </div>

                            {!!calculation &&
                            <div className="row mt-3 mb-3 ml-1">
                                <div className="col-12">
                                    <p>Стоимость доставки: <b>{calculation['total_sum']}</b> {calculation['currency']}
                                    </p>
                                    <p className="small">Включая
                                        упаковку:<span> {calculation['services'][0].code} = {calculation['services'][0].sum} {calculation.currency}</span>
                                    </p>
                                    <p>Минимальное время доставки (в рабочих днях): {calculation['period_min']}</p>
                                    <p>Максимальное время доставки (в рабочих днях): {calculation['period_max']}</p>
                                </div>
                            </div>}

                        </div>

                    </MuiBox>
                </MuiFade>

            </MuiModal>

        </>
    )
        ;
});

export default Address;