import {Card, Col, Container, Row, Spinner} from "react-bootstrap";
import {Link} from 'react-router-dom';
import {FaThLarge} from "react-icons/fa";
import {observer} from "mobx-react";
import Store from "../stores/CustomerStore";

const Catalog = observer((props: any) => {

    const {isLoaded, categories} = Store
    return (
        <>
            <Container style={{marginTop: 100}}>
                <Row>
                    <Col className="my-auto">
                        <h1><FaThLarge/> Каталог</h1>
                    </Col>
                </Row>

                {isLoaded && <Spinner animation="border"/>}

                <Row className="justify-content-center mb-3">
                    {categories.map((category, i) => {
                        console.log(`${i}.${JSON.stringify(category)}`);
                        // Return the element. Also pass key
                        return (<Col xl={4} lg={6} md={6} sm={12} className="mb-3">
                                <Card className="shadow">

                                    <Link to={`/catalog/category/${category['id']}`}>
                                        <Card.Img variant="top" src={category['s3file'] ? '/file?name=' + category['s3file'] : 'http://placehold.it/200x200'}/>
                                    </Link>

                                    <Card.Body>
                                        <Card.Title className="text-center"><h2>{category['name']}</h2></Card.Title>
                                        <Card.Text>

                                        </Card.Text>
                                    </Card.Body>

                                </Card>
                            </Col>)
                    })}

                </Row>

            </Container>


        </>
    );
});

export default Catalog;
