export const ACTION_ADD = 'ADD';
export const ACTION_REMOVE = 'REMOVE';
export const ACTION_REMOVE_ALL = 'REMOVE_ALL';

export const PaymentStatus = {
    NEW: {name: "NEW", ru: "Новый заказ"},
    AWAITING_PAYMENT: {name: "AWAITING_PAYMENT", ru: "Ожидает оплаты"},
    PAID: {name: "PAID", ru: "Оплачен"},
    AWAITING_SHIPPED: {name: "AWAITING_SHIPPED", ru: "Ожидает отправки"},
    SHIPPED: {name: "SHIPPED", ru: "Отправлен"},
    DELIVERED: {name: "DELIVERED", ru: "Доставлен"},
    COMPLETED: {name: "COMPLETED", ru: "Завершен"},
    CANCELED: {name: "CANCELED", ru: "Отменён"}
}
