import {action, computed, makeObservable, observable} from "mobx";
import axios from "axios";
import { Store as NotificationStore} from 'react-notifications-component';

class CustomerStore {
    @observable categories = [];
    @observable isLoaded = false;

    @observable paymentTypes = [];
    @observable products = [];
    @observable product: any;
    @observable productFile = '';
    @observable productRating = {productId: '', average: 0.0, value: 0.0, votes: 0};
    @observable productFiles = [];
    @observable cart = {purchases: [], sum: 0};
    @observable cartPositions = [];
    @observable orders = [];
    @observable address: any;
    @observable order: any;
    @observable payment: any;
    @observable qr: any;
    @observable privacy = '';
    @observable agreement = '';
    @observable contacts = '';
    @observable countries = [];
    @observable deliveryTypes = [];
    @observable deliveries = [];
    @observable deliveryList = [
            {code: "russian_post", name: "Почта России", img: "russian_post.jpg"},
            {code: "cis_post", name: "Почта СНГ", img: "cis_post.jpg"},
            {code: "int_post", name: "Международная почта", img: "int_post.jpg"},
            {code: "cdek", name: "CDEK", img: "cdek.jpg"}
        ];
    @observable regions = [];
    @observable cities = [];
    @observable points = [];
    @observable cdekPointInfo: any;
    @observable calculation: any;

    constructor() {
        makeObservable(this)
        this.fetchCategories({})

        axios.interceptors.response.use(response => {
                return this.checkErrors(response);
            },
            error => {
                console.log('Error', error.message)
                this.addNotificationMethod('danger', 'Ошибка сервера', error.message)
            }
        );
    }

    addNotificationMethod(type: any, title: any, message: any) {
        NotificationStore.addNotification({
            type: type,
            title: title,
            message: message,
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    }

    checkErrors(response: any) {
        if (!!response.data.errors && response.data.errors.length > 0) {
            this.addNotificationMethod('danger', 'Ошибка!', JSON.stringify(response.data.errors))
        }
        return response;
    }

    @action fetchRegions = () => {
        this.regions = []
        this.cities = []
        this.points = []
        this.calculation = null
        axios
            .get('/api/v1/cdek/regions')
            .then(response => {
                this.regions = response.data.data
                this.cities = []
                this.points = []
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action fetchPaymentTypes = () => {
        axios
            .get('/api/v1/paymentType')
            .then(response => {
                this.paymentTypes = response.data.data
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action fetchCities = (region: any) => {
        axios
            .get('/api/v1/cdek/cities',{params:{region: region}})
            .then(response => {
                this.cities = response.data.data
                this.points = []
                this.calculation = null
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action fetchPoints = (city: any) => {
        axios
            .get('/api/v1/cdek/deliverypoints',{params:{city: city}})
            .then(response => {
                this.points = response.data.data
                this.calculation = null
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action calculateDelivery = (info: any) => {
        axios
            .post('/api/v1/cdek/calculate', info)
            .then(response => {
                this.calculation = response.data.data
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action fetchPointInfo = (city: any, pointCode: any) => {
        axios
            .get('/api/v1/cdek/deliverypoints',{params:{city: city, pointCode: pointCode}})
            .then(response => {
                this.cdekPointInfo = response.data.data
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action fetchCountries = () => {
        axios
            .get('/api/v1/country')
            .then(response => {
                this.countries = response.data.data
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action fetchDeliveryTypes = () => {
        axios
            .get('/api/v1/delivery/type')
            .then(response => {
                this.deliveryTypes = response.data.data
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action fetchDeliveries = (type: any) => {
        axios
            .get('/api/v1/delivery?type=' + type)
            .then(response => {
                this.deliveries = []
                this.deliveries = response.data.data
                this.calculation = null
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action fetchAllDeliveries = () => {
        axios
            .get('/api/v1/delivery')
            .then(response => {
                this.deliveries = []
                this.deliveries = response.data.data
                this.calculation = null
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action fetchCategories = (params: object) => {
        this.isLoaded = true;
        axios
            .get('/api/v1/category', {params: params})
            .then(response => {
                this.categories = response.data.data
                this.isLoaded = false
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action fetchProducts = (id: string, params: object) => {
        this.isLoaded = true;
        this.products = [];
        axios
            .get(`/api/v1/category/${id}`, {params: params})
            .then(response => {
                this.products = response.data.data
                this.isLoaded = false
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action searchProducts = (params: any) => {
        this.isLoaded = true;
        this.products = [];
        axios
            .get(`/api/v1/search`, {params: params})
            .then(response => {
                this.products = response.data.data
                this.isLoaded = false
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action fetchProduct = (id: string, itemId: string, params: object) => {
        this.isLoaded = true;
        this.product = null;
        axios
            .get(`/api/v1/category/${id}/product/${itemId}`, {params: params})
            .then(response => {
                this.product = response.data.data
                this.productFile = this.product['product']['s3file']
                this.fetchFileFolder(this.product['product']['s3folder'])
                this.isLoaded = false
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action fetchRating = (params: object) => {
        axios
            .get('/api/v1/rating', {params: params})
            .then(response => {
                this.productRating = response.data.data
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action fetchStaticResource = (ref: String) => {
        axios
            .get('/api/v1/static/' + ref)
            .then(response => {
                if (ref === 'privacy') {
                    this.privacy = response.data.data.content
                }
                if (ref === 'agreement') {
                    this.agreement = response.data.data.content
                }
                if (ref === 'contacts') {
                    this.contacts = response.data.data.content
                }
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action voteProduct = (token?: String, vote?: Object) => {
        console.log("Change address")
        axios
            .post(`/api/v1/vote`, vote, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                this.productRating = response.data.data
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action setProductFile = (file: string) => {
        this.productFile = file;
    }

    @action cleanProductFiles = () => {
        this.productFiles = [];
    }

    @action fetchFileFolder = (folderUuid: string) => {
        axios
            .get(`/file/folder/${folderUuid}`)
            .then(response => {
                this.productFiles = response.data.data
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action fetchCartPositions = (id: string, itemId: string, params: object) => {
        this.cartPositions = [];
        axios
            .get(`/api/v1/category/${id}/product/${itemId}/cart`, {params: params})
            .then(response => {
                this.cartPositions = response.data.data
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action createOneClickOrder = (request: object) => {
        axios
            .post(`/api/v1/payment/express`, request)
            .then(response => {
                this.payment = response.data.data
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action fetchCart = (token?: String) => {
        this.isLoaded = true;
        axios
            .get(`/api/v1/cart`, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                this.cart = response.data.data
                this.isLoaded = false
            })
            .catch((error) => (console.log('Error', error.message)));
    }
// TODO это костыльно, подумать
    @action updateCart = (token?: String) => {
        axios
            .get(`/api/v1/cart`, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                this.cart = response.data.data
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action actionCart = (id: String, action: String, token?: String) => {
        axios
            .put(`/api/v1/cart`, {
                cartPositionId: id,
                count: 1,
                action: action
            }, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                console.log('Response: ', JSON.stringify(response))
                this.updateCart(token)
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action fetchAddress = (token?: String) => {
        this.isLoaded = true;
        axios
            .get(`/api/v1/address`, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                this.address = response.data.data
                this.isLoaded = false
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action changeAddress = (token?: String, address?: Object) => {
        console.log("Change address")
        axios
            .post(`/api/v1/address`, address, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                this.address = response.data.data
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action createOrder = (token?: String, order?: Object) => {
        console.log("Create order")
        axios
            .post(`/api/v1/order`, order, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                this.order = response.data.data
                this.fetchCart(token)
                this.createPayment(token, this.order.id)
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action setOrder = (order?: Object) => {
        console.log("Selected order:", JSON.stringify(order))
        this.order = order
    }

    @action createPayment = (token?: String, orderId?: Number) => {
        console.log("Create order")
        axios
            .post(`/api/v1/payment`, {orderId: orderId, paymentType: "bank_card"}, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                this.payment = response.data.data
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action fetchOrders = (token?: String, params?: object) => {
        this.isLoaded = true;
        axios
            .get(`/api/v1/order`, {params: params, headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                this.orders = response.data.data
                this.isLoaded = false
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @action fetchQr = (token?: String, params?: object) => {
        this.isLoaded = true;
        axios
            .get(`/api/v1/qr`, {
                responseType: 'arraybuffer',
                params: params,
                headers: {Authorization: `Bearer ${token}`}
            })
            .then(response => {
                let base64 = Buffer.from(response.data, 'binary').toString('base64')
                this.qr = {info: params, data: base64};
                this.isLoaded = false
            })
    }

    @action fetchPdf = (token?: String, params?: object) => {
        this.isLoaded = true;
        axios
            .get(`/api/v1/pdf`, {
                responseType: 'arraybuffer',
                params: params,
                headers: {Authorization: `Bearer ${token}`}
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `invoice_${new Date().toISOString()}.pdf`); //or any other extension
                document.body.appendChild(link);
                link.click();
                this.isLoaded = false
            })
            .catch((error) => (console.log('Error', error.message)));
    }

    @computed get count() {
        return this.categories.length
    }

    @computed get price() {
        if (this.product) {
            return (100 - this.product.discount.percent) * this.product.product.price / 100
        } else {
            return 0;
        }

    }
}

const Store = new CustomerStore();

export default Store;