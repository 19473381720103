import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Main from "./containers/Main";
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

const App = () => {

    return (
        <>
            <ReactNotifications />
            <Main/>
        </>
    );
};
export default App;
