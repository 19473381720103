import React from 'react';
import {Route, Switch} from "react-router-dom";
import Catalog from "./Catalog";
import Category from "./Category";
import Item from "./Item";

const CatalogRoot = () => {
    return (
        <Switch>
            <Route exact path='/catalog' component={Catalog}/>
            <Route exact path='/catalog/category/:catId' component={Category}/>
            <Route exact path='/catalog/category/:catId/item/:itemId' component={Item}/>
        </Switch>
    );
};

export default CatalogRoot;
