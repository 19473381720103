import React from 'react';
import {Carousel, Container} from "react-bootstrap";
import durbenImg from '../assets/durben.jpg'
import khoaImg from '../assets/khoa.jpg'

const CarouselBox = () => {
    return (
        <Container fluid="lg" style={{marginTop:100}}>
            <Carousel className="shadow mb-5">
                <Carousel.Item>
                    <img
                        className="d-block w-100 rounded"
                        src={durbenImg}
                        alt="board"
                    />
                    <Carousel.Caption>
                        <h3>PurpleSun</h3>
                        <p>Мы создаем деки и радуемся жизни! Выбирай свою!</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 rounded shadow"
                        src={khoaImg}
                        alt="board"
                    />
                    <Carousel.Caption>
                        <h3>PurpleSun</h3>
                        <p>Мы собираем фингерборды и изготавливаем комплектующие к ним.</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </Container>
    );
};

export default CarouselBox;
