import React, {useLayoutEffect} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {observer} from "mobx-react";
import Store from "../stores/CustomerStore";

const Agreement = observer((props: any) => {

    const {fetchStaticResource, agreement} = Store

    useLayoutEffect(() => {
        // "This only happens ONCE.  But it still happens AFTER the initial render."
        fetchStaticResource("agreement")
    }, []);

    return (
        <>
            <Container style={{marginTop: 100}}>
                <Row>
                    <Col dangerouslySetInnerHTML={{__html: agreement !=='' ? agreement : ' ' }}>
                    </Col>
                </Row>
            </Container>
        </>
    );
});

export default Agreement;
