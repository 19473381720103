import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {FaInfoCircle} from "react-icons/fa";
import {Link} from "react-router-dom";

const About = () => {
    return (
        <>
            <Container style={{marginTop: 100}}>
                <Row>
                    <Col>
                        <h1><FaInfoCircle/></h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                            Интернет магазин PurpleSun — это лучший выбор!
                        </p>
                        <p>
                            В каталоге товаров вы найдете большой ассортимент дек для фингерборда, колёс, а также траки
                            и другие
                            запчасти для фингерборда.
                            Большую часть товаров мы производим самостоятельно, постоянно двигаясь вперед, создавая что
                            то новое!
                        </p>
                        <p>
                            Бесплатная доставка в магазине Psfb's действует при заказе от 2500.
                        </p>
                        <p>
                            Основной особенностью производства товаров PurpleSun Fingerboard's является индивидуальный
                            подход к
                            каждой детали. Мы следим за качеством своей продукции.
                        </p>
                        <p>
                            С каждым днем все больше и больше людей узнают продукцию PurpleSun , делая уникальный
                            подарок себе или
                            знакомым. У вас не будет сомнений совершая покупку именно в нашем магазине.
                        </p>
                        <p>
                            Мы очень надеемся что наш интернет магазин запомнился каждому покупателю, и ждём вас снова
                            на страницы
                            нашего каталога для любимых покупок!
                        </p>
                        <p>
                            <Link to={'/privacy'}>
                                Политика конфиденциальности
                            </Link>
                        </p>
                        <p>
                            <Link to={'/agreement'}>
                                Пользовательское соглашение
                            </Link>
                        </p>
                    </Col>
                </Row>

            </Container>
        </>
    );
};

export default About;
