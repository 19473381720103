import React from 'react';
import Navigation from "../components/Navigation";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Cart from "../components/Cart";
import CarouselBox from "../components/CarouselBox";
import CatalogRoot from "../components/CatalogRoot";
import {PrivateRoute} from "./utils";
import {useKeycloak} from "@react-keycloak/web";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import Address from "../components/Address";
import Orders from "../components/Orders";
import About from "../components/About";
import Privacy from "../components/Privacy";
import CookieConsent from "react-cookie-consent";
import Agreement from '../components/Agreement';
import Contacts from "../components/Contacts";
import {YMInitializer} from 'react-yandex-metrika';
import Search from "../components/Search";
import MobileApp from "../components/MobileApp";


const Main = () => {
    const {keycloak, initialized} = useKeycloak()

    if (!initialized) {
        return (
            <>
                <YMInitializer accounts={[85862571]} options={{webvisor: true}}/>

                <Container style={{marginTop: 100}}>
                    <Row className="justify-content-center">
                        <Col className="justify-content-center">
                            <Spinner animation="border"/>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }

    return (
        <>
            <Router>
                <Navigation/>

                <Switch>
                    <Route exact path='/'>
                        <CarouselBox/>
                        <MobileApp/>
                    </Route>
                    <Route exact path='/home'>
                        <CarouselBox/>
                        <MobileApp/>
                    </Route>
                    <Route path='/catalog'>
                        <CatalogRoot/>
                    </Route>
                    <PrivateRoute path="/cart" component={Cart}/>
                    <PrivateRoute path="/address" component={Address}/>
                    <PrivateRoute path="/orders" component={Orders}/>
                    <Route path="/about" component={About}/>
                    <Route path="/privacy" component={Privacy}/>
                    <Route path="/agreement" component={Agreement}/>
                    <Route path="/contacts" component={Contacts}/>
                    <Route exact path='/search' component={Search}/>
                    {/*<Route path='/contacts'>*/}
                    {/*    <Contacts />*/}
                    {/*</Route>*/}
                    {/*<Route path='/blog'>*/}
                    {/*    <Blog />*/}
                    {/*</Route>*/}
                    {/*<Route path='/cart'>*/}
                    {/*    <Cart />*/}
                    {/*</Route>*/}
                </Switch>
            </Router>
            <CookieConsent
                buttonText="Х"
                buttonClasses={'btn btn-outline-warning'}
                cookieName="privacyAgree"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "16px" }}
            >
                Мы используем файлы cookie.
                Продолжив работу с сайтом, вы соглашаетесь с
                <a href={'/privacy'}> Политикой обработки персональных данных </a>
                и <a href={'/agreement'}>правилами пользования сайтом</a>.
            </CookieConsent>

        </>

    );
};

export default Main;
