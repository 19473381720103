import React, {useLayoutEffect} from 'react';
import {observer} from "mobx-react";
import Store from "../stores/CustomerStore";
import {useKeycloak} from "@react-keycloak/web";
import {Button, Card, Col, Container, Row, Spinner, Tab, Tabs} from "react-bootstrap";
import {FaCcVisa, FaCreditCard, FaFilePdf, FaQrcode, FaThLarge, FaWindowClose, FaYandex} from "react-icons/fa";
import Moment from "react-moment";
import Modal from "react-modal";
import {PaymentStatus} from "../utils/const";
import MuiButton from "@mui/material/Button";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {amber, blue} from "@mui/material/colors";

const Orders = observer((props: any) => {

        const {payment, createPayment, fetchOrders, orders, isLoaded, order, setOrder, qr, fetchQr, fetchPdf} = Store
        const {keycloak} = useKeycloak()

        const [displayPayment, setPaymentIsDisplayed] = React.useState(false);
        const [displayOrder, setOrderIsDisplayed] = React.useState(false);

        const theme = createTheme({
            palette: {
                primary: blue,
                secondary: amber,
            },
        });

        function orderDetails(order: any) {
            setOrder(order)
            setOrderIsDisplayed(true)
            console.log("Order", JSON.stringify(order))
        }

        function prepareTitle(status: any) {
            return `${status.ru} (${countOrders(status)})`
        }

        function countOrders(status: any) {
            return orders.filter(order => order['status']['name'] === status.name).length
        }

        function filterOrders(status: any, variant: string) {
            return orders.filter(order => order['status']['name'] === status.name).map((order, i) => {
                return (<Col xl={3} lg={4} md={6} sm={12} className="col-12 mb-5">
                    <Card className="shadow-sm">
                        <Card.Header className={variant}>
                            #{order['id']}
                        </Card.Header>

                        <Card.Body style={{cursor: 'pointer'}} onClick={() => {
                            orderDetails(order)
                        }}>
                            <Row>
                                <dt className="col-sm-5"><small>Товаров</small></dt>
                                <dd className="col-sm-7 font-weight-bold">{order['orderInfo']['positions']['length']}</dd>

                                <dt className="col-sm-5"><small>На сумму</small></dt>
                                <dd className="col-sm-7 font-weight-bold">{order['orderInfo']['sum']}&#8381;</dd>

                                <dt className="col-sm-5"><small>Дата</small></dt>
                                <dd className="col-sm-7">
                                    <Moment format="YYYY/MM/DD">
                                        {order['created']}
                                    </Moment>
                                </dd>
                            </Row>
                        </Card.Body>

                        <Card.Footer className="bg-white">
                            <Row className="mb-2">
                                {order['orderInfo']['paymentMethod']['name'] === 'CARD' && order['status']['name'] === PaymentStatus.AWAITING_PAYMENT.name &&
                                (<Col className="col text-center">
                                    <ThemeProvider theme={theme}>
                                        <MuiButton
                                            type="button"
                                            startIcon={<FaCreditCard/>}
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => {
                                                pay(order)
                                            }}>
                                            Оплатить
                                        </MuiButton>
                                    </ThemeProvider>
                                </Col>)}
                                {order['orderInfo']['paymentMethod']['name'] === 'BANK' && order['status']['name'] === PaymentStatus.NEW.name &&
                                (<Col className="col text-center">
                                    <ThemeProvider theme={theme}>
                                        <MuiButton
                                            type="button"
                                            startIcon={<FaFilePdf/>}
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => {
                                                fetchPdf(keycloak.token, {
                                                    order: order['id'],
                                                    sendToEmail: false
                                                })
                                                setPaymentIsDisplayed(false)
                                            }}>
                                            Оплатить счет
                                        </MuiButton>
                                    </ThemeProvider>
                                </Col>)}
                                {order['receiptUrl'] && (
                                    <Col className="col text-center"><a href={order['receiptUrl']} target="_blank"
                                                                        rel="noreferrer">Чек</a></Col>)}
                            </Row>

                            {order['trackNumber'] && (<Row className="mb-2"><Col className="col text-center">{`Трек-номер: ${order['trackNumber']}`}</Col></Row>)}

                        </Card.Footer>
                    </Card>
                </Col>)
            })
        }

        function pay(order: any) {
            console.log("Payment", JSON.stringify(order))
            setOrder(order)
            setPaymentIsDisplayed(true)
            createPayment(keycloak.token, order.id)
        }

        function getQr(order: any) {
            console.log("Qr code", JSON.stringify(order))
            fetchQr(keycloak.token, {order: order})
        }

        function closePaymentModal() {
            setPaymentIsDisplayed(false)
        }

        function closeOrderModal() {
            setOrderIsDisplayed(false)
        }


    function getDeliveryType() {
        if (!!order.orderInfo && !!order.orderInfo.deliveryAddress ) {
            return order.orderInfo.deliveryAddress.type
        } else {
            return 'russian_post'
        }
    }

        useLayoutEffect(() => {
            // "This only happens ONCE.  But it still happens AFTER the initial render."
            fetchOrders(keycloak.token);
        }, []);

        return (
            <>
                <Container style={{marginTop: 100}}>
                    <Row>
                        <Col>
                            <h1><FaThLarge/> Мои заказы ({orders.length})</h1>
                        </Col>
                    </Row>

                    <Tabs defaultActiveKey={PaymentStatus.AWAITING_PAYMENT.name}
                          id="controlled-tab-example">
                        <Tab eventKey={PaymentStatus.NEW.name} title={prepareTitle(PaymentStatus.NEW)}>
                            <Row className="mb-3 mt-3">
                                {filterOrders(PaymentStatus.NEW, "bg-danger text-light")}
                            </Row>
                        </Tab>
                        <Tab eventKey={PaymentStatus.AWAITING_PAYMENT.name}
                             title={prepareTitle(PaymentStatus.AWAITING_PAYMENT)}>
                            <Row className="mb-3 mt-3">
                                {filterOrders(PaymentStatus.AWAITING_PAYMENT, "bg-primary text-light")}
                            </Row>
                        </Tab>
                        <Tab eventKey={PaymentStatus.PAID.name} title={prepareTitle(PaymentStatus.PAID)}>
                            <Row className="mb-3 mt-3">
                                {filterOrders(PaymentStatus.PAID, "bg-info text-light")}
                            </Row>
                        </Tab>
                        <Tab eventKey={PaymentStatus.AWAITING_SHIPPED.name}
                             title={prepareTitle(PaymentStatus.AWAITING_SHIPPED)}>
                            <Row className="mb-3 mt-3">
                                {filterOrders(PaymentStatus.AWAITING_SHIPPED, "bg-primary text-light")}
                            </Row>
                        </Tab>
                        <Tab eventKey={PaymentStatus.SHIPPED.name} title={prepareTitle(PaymentStatus.SHIPPED)}>
                            <Row className="mb-3 mt-3">
                                {filterOrders(PaymentStatus.SHIPPED, "bg-primary text-light")}
                            </Row>
                        </Tab>
                        <Tab eventKey={PaymentStatus.DELIVERED.name} title={prepareTitle(PaymentStatus.DELIVERED)}>
                            <Row className="mb-3 mt-3">
                                {filterOrders(PaymentStatus.DELIVERED, "bg-success text-white")}
                            </Row>
                        </Tab>
                        <Tab eventKey={PaymentStatus.COMPLETED.name} title={prepareTitle(PaymentStatus.COMPLETED)}>
                            <Row className="mb-3 mt-3">
                                {filterOrders(PaymentStatus.COMPLETED, "bg-light text-dark")}
                            </Row>
                        </Tab>
                        <Tab eventKey={PaymentStatus.CANCELED.name} title={prepareTitle(PaymentStatus.CANCELED)}>
                            <Row className="mb-3 mt-3">
                                {filterOrders(PaymentStatus.CANCELED, "bg-dark text-white")}
                            </Row>
                        </Tab>
                    </Tabs>

                    {isLoaded && <Spinner animation="border"/>}

                </Container>
                <Modal
                    isOpen={displayPayment}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={closePaymentModal}
                    // style={customStyles}
                    contentLabel="Оплата"
                >


                    <div className="container">

                        <div className="modal-header">

                            <h4>
                                <span><ins>Оплата заказа</ins></span>
                            </h4>
                            <FaWindowClose className="float-right c-pointer mt-2" onClick={closePaymentModal}/>

                        </div>
                        <div className="row mt-3 mb-3 ml-1">
                            <div className="col-12">
                                <p>
                                    Номер вашего заказа <b>{!!order && order.id}</b>
                                </p>
                                <div className="modal-footer">
                                    {!payment &&
                                    <p className="small">Подготавливаю платежную форму ... <Spinner animation="grow"
                                                                                                    variant="info"/></p>}
                                    {!!payment && payment['orderId'] === order.id && <p>
                                        <span><img className="mr-2" height="30px" alt="yookassa" src="/yookassa.svg" /></span>
                                        <ThemeProvider theme={theme}>
                                            <MuiButton
                                                href={payment.confirmationUrl}
                                                variant="contained"
                                                startIcon={<FaCreditCard />}
                                                color="secondary">
                                                Оплатить
                                            </MuiButton>
                                        </ThemeProvider>
                                    </p>}
                                </div>
                            </div>
                        </div>

                    </div>


                </Modal>

                <Modal
                    isOpen={displayOrder}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={closeOrderModal}
                    // style={customStyles}
                    contentLabel="Описание заказа"
                >


                    {!!order && (<Container>
                        <Row>
                            <Col>

                                <div className="modal-header">

                                    <h4>
                            <span><ins>№ {!!order.id && `${order.id} от `} <Moment
                                format="DD-MM-YYYY">{order.created}</Moment>
                            </ins></span>
                                    </h4>
                                    <FaWindowClose className="float-right c-pointer mt-2" onClick={closeOrderModal}/>

                                </div>
                                <Row className="mt-3 mb-3 ml-1">
                                    <Col className="col-12">


                                        <dl className="row">

                                            <dt className="col-sm-3 small">Номер заказа</dt>
                                            <dd className="col-sm-9 font-weight-bold">{order.id}</dd>

                                            <dt className="col-sm-3 small">Дата заказа</dt>
                                            <dd className="col-sm-9"><Moment
                                                format="DD-MM-YYYY HH:mm:ss">{order.created}</Moment></dd>

                                            <dt className="col-sm-3 small">Статус</dt>
                                            <dd className="col-sm-9">{order.status.description}</dd>

                                            <dt className="col-sm-3 small">Форма оплаты</dt>
                                            <dd className="col-sm-9">
                                                {order.orderInfo.paymentMethod.description}
                                                {order.orderInfo.paymentMethod.name === 'BANK' && (<span className="ml-2">

              {(!qr || qr.info.order !== order.id) && <ThemeProvider theme={theme}>
                  <MuiButton
                      type="button"
                      startIcon={<FaQrcode/>}
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                          getQr(order.id)
                      }}>
                      QR-код
                  </MuiButton>
              </ThemeProvider>}
                            </span>)}
                                            </dd>

                                            <dt className="col-sm-3 small">Сумма</dt>
                                            <dd className="col-sm-9">{order.orderInfo.sum}&#8381;</dd>

                                            <dt className="col-sm-3 small">Email</dt>
                                            <dd className="col-sm-9">{order.createdby}</dd>

                                        </dl>
                                    </Col>
                                </Row>

                                {!!qr && qr.info.order === order.id && (<Row>
                                    <Col>
                                        <img className="rounded" src={'data:image/png;base64,' + qr.data}/>
                                    </Col>
                                    <Col>
            <span className="small">Данный QR-код можно отсканировать в мобильном приложении банка,
              проверено в приложениях Тинькофф и ВТБ</span>
                                    </Col>
                                </Row>)}

                                <div className="table table-responsive table-hover">
                                    <table className="table">
                                        <tbody>

                                        {order.orderInfo.positions.map((position: any, i: Number) => {
                                            return (<tr>

                                                <td>
                                                    <img className="rounded" width="40"
                                                         src={position.catalogEntry.product.s3file ? '/file?name=' + position.catalogEntry.product.s3file : 'http://placehold.it/40x40'}/>
                                                </td>
                                                <td>{position.catalogEntry.product.name}</td>
                                                <td>{position.count}<span className="small"> шт.</span></td>
                                                <td>{position.sum}&#8381;</td>
                                            </tr>)
                                        })}
                                        <tr>
                                            <td></td>
                                            <td>Доставка: </td>
                                            <td></td>
                                            <td>{order.orderInfo.deliveryPrice}&#8381;</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td className="font-weight-bold">Итого:</td>
                                            <td>{order.orderInfo.totalSum}&#8381;</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="table table-responsive table-hover" v-if="order.id">
                                    <table className="table">
                                        <tbody>
                                        <tr>
                                            <td className="small">Статус</td>
                                            <td>

                                                <div className="btn-group dropright">
                                                    <button type="button" className="btn btn-outline-primary"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true" aria-expanded="false">
                                                        {order.status.description}
                                                    </button>

                                                    {/*// TODO нужна кнопка*/}
                                                    {/*{order['orderInfo']['paymentMethod']['name'] === 'CARD' && order['status']['name'] === PaymentStatus.AWAITING_PAYMENT.name &&*/}
                                                    {/*(<ThemeProvider theme={theme}>*/}
                                                    {/*    <MuiButton*/}
                                                    {/*        type="button"*/}
                                                    {/*        startIcon={<FaCreditCard/>}*/}
                                                    {/*        variant="contained"*/}
                                                    {/*        color="secondary"*/}
                                                    {/*        onClick={() => {*/}
                                                    {/*            pay(order)*/}
                                                    {/*        }}>*/}
                                                    {/*        Оплатить*/}
                                                    {/*    </MuiButton>*/}
                                                    {/*</ThemeProvider>)}*/}

                                                    <div className="dropdown-menu">
                                                        {/*        <a v-for="(status, index) in orderStatuses" :key="index" class="dropdown-item"*/}
                                                        {/*        @click="changeStatus(status)">*/}
                                                        {/*        {{status.description}}*/}
                                                        {/*        <span v-if="status.name === order.status.name"><font-awesome-icon*/}
                                                        {/*            icon="check"/></span>*/}
                                                        {/*    </a>*/}
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Адрес доставки</td>
                                            <td>
                                                <Tabs
                                                    id="address-tab-view"
                                                    activeKey={getDeliveryType()}
                                                    className="mb-3"
                                                >
                                                    <Tab eventKey="russian_post" title="Почта России" disabled>

                                                        < Row className="mb-3 p-2 bg-light shadow-sm">

                                                            <Col className="my-auto">

                                                                {!!order.orderInfo && !!order.orderInfo.deliveryAddress && (<dl className="row">

                                                                    <dt className="col-sm-3">Индекс</dt>
                                                                    <dd className="col-sm-9">{order.orderInfo.deliveryAddress.zipCode}</dd>

                                                                    <dt className="col-sm-3">Область/район</dt>
                                                                    <dd className="col-sm-9">{order.orderInfo.deliveryAddress.state}</dd>

                                                                    <dt className="col-sm-3">Город/Населенный пункт</dt>
                                                                    <dd className="col-sm-9">{order.orderInfo.deliveryAddress.city}</dd>

                                                                    <dt className="col-sm-3">Улица</dt>
                                                                    <dd className="col-sm-9">{order.orderInfo.deliveryAddress.street}</dd>

                                                                    <dt className="col-sm-3">Получатель</dt>
                                                                    <dd className="col-sm-9">{order.orderInfo.deliveryAddress.recipient}</dd>

                                                                </dl>)}

                                                            </Col>

                                                        </Row>

                                                    </Tab>

                                                    <Tab eventKey="cis_post" title="Почта СНГ" disabled>

                                                        < Row className="mb-3 p-2 bg-light shadow-sm">

                                                            <Col className="my-auto">

                                                                {!!order.orderInfo && !!order.orderInfo.deliveryAddress && (<dl className="row">

                                                                    <dt className="col-sm-3">Страна</dt>
                                                                    <dd className="col-sm-9">{order.orderInfo.deliveryAddress.country}</dd>

                                                                    <dt className="col-sm-3">Полный адрес</dt>
                                                                    <dd className="col-sm-9">{order.orderInfo.deliveryAddress.fullAddress}</dd>

                                                                    <dt className="col-sm-3">Получатель</dt>
                                                                    <dd className="col-sm-9">{order.orderInfo.deliveryAddress.recipient}</dd>

                                                                    <dt className="col-sm-3">Дополнительная информация</dt>
                                                                    <dd className="col-sm-9">{order.orderInfo.deliveryAddress.additionalInfo}</dd>

                                                                </dl>)}

                                                            </Col>

                                                        </Row>

                                                    </Tab>

                                                    <Tab eventKey="cdek" title="CDEK" disabled>
                                                        < Row className="mb-3 p-2 bg-light shadow-sm">

                                                            <Col className="my-auto">

                                                                {!!order.orderInfo && !!order.orderInfo.deliveryAddress && (<dl className="row">

                                                                    <dt className="col-sm-3">Пункт назначения CDEK (номер)</dt>
                                                                    <dd className="col-sm-9">{order.orderInfo.deliveryAddress.toLocation}</dd>

                                                                    <dt className="col-sm-3">Код тарифа</dt>
                                                                    <dd className="col-sm-9">{order.orderInfo.deliveryAddress.tariffCode}</dd>

                                                                    <dt className="col-sm-3">Полный адрес</dt>
                                                                    <dd className="col-sm-9">{order.orderInfo.deliveryAddress.fullAddress}</dd>

                                                                    <dt className="col-sm-3">Получатель</dt>
                                                                    <dd className="col-sm-9">{order.orderInfo.deliveryAddress.recipient}</dd>

                                                                    <dt className="col-sm-3">Телефон</dt>
                                                                    <dd className="col-sm-9">{order.orderInfo.deliveryAddress.phone}</dd>

                                                                </dl>)}

                                                            </Col>

                                                        </Row>
                                                    </Tab>
                                                </Tabs>
                                            </td>
                                        </tr>
                                        {!!order.trackNumber && (<tr>
                                            <td>Трек-номер</td>
                                            <td>{order.trackNumber}</td>
                                        </tr>)}
                                        </tbody>
                                    </table>
                                </div>

                            </Col>
                        </Row>
                    </Container>)}
                </Modal>
            </>
        );
    }
);

export default Orders;
