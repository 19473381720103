import React, {useLayoutEffect} from 'react';
import {Button, Col, Container, Form, ModalFooter, Row, Spinner} from "react-bootstrap";
import {
    FaAddressCard, FaCalculator,
    FaCreditCard,
    FaEdit, FaFilePdf,
    FaMinusSquare,
    FaPlusSquare,
    FaQrcode,
    FaShoppingCart,
    FaTrain,
    FaTrash,
    FaWindowClose,
    FaYandex
} from "react-icons/fa";
import {useKeycloak} from "@react-keycloak/web";
import {observer} from "mobx-react";
import Store from "../stores/CustomerStore";
import {ACTION_ADD, ACTION_REMOVE, ACTION_REMOVE_ALL} from "../utils/const";
import Modal from "react-modal";
import MuiButton from "@mui/material/Button";
import MuiBackdrop from "@mui/material/Backdrop";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {blue, amber} from '@mui/material/colors';
import MuiFade from "@mui/material/Fade";
import MuiBox from "@mui/material/Box";
import MuiModal from "@mui/material/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const Cart = observer((props: any) => {

    const {
        isLoaded,
        cart,
        fetchCart,
        actionCart,
        address,
        fetchAddress,
        order,
        createOrder,
        payment,
        qr,
        fetchQr,
        fetchPdf,
        deliveryTypes,
        fetchDeliveryTypes,
        deliveries,
        fetchDeliveries,
        regions,
        cities,
        points,
        calculation,
        fetchRegions,
        fetchCities,
        fetchPoints,
        calculateDelivery,
        paymentTypes,
        fetchPaymentTypes
    } = Store
    const {keycloak} = useKeycloak()

    const [displayAddress, setAddressIsDisplayed] = React.useState(false);
    const [displayPayment, setPaymentIsDisplayed] = React.useState(false);
    const [paymentMethod, setPaymentMethod] = React.useState('');
    const [cdekModalIsOpen, setIsOpenCdekModal] = React.useState(false);
    const [selectedDelivery, setSelectedDelivery] = React.useState('');
    const [cdekInfo, setCdekInfo] = React.useState({});
    const [sendInvoiceToEmail, setSendInvoiceToEmail] = React.useState(false);

    const deliveryType = React.createRef<HTMLSelectElement>();
    const delivery = React.createRef<HTMLSelectElement>();

    const cartCdekRegion = React.createRef<HTMLSelectElement>();
    const cartCdekCity = React.createRef<HTMLSelectElement>();
    const cartCdekPoint = React.createRef<HTMLSelectElement>();
    const cartCdekTariff = React.createRef<HTMLSelectElement>();


    const redFont = {
        'color': 'IndianRed',
        'font-weight': 'bold'
    };

    const theme = createTheme({
        palette: {
            primary: blue,
            secondary: amber,
        },
    });

    function action(position: String, action: String) {
        console.log(`${action} position: id=${position}`);
        actionCart(position, action, keycloak.token);
    }

    function showAddress() {
        setAddressIsDisplayed(true);
    }

    function confirmOrder() {
        createOrder(keycloak.token, {
            paymentMethod: paymentMethod
        })
        setPaymentIsDisplayed(true); // TODO модальное окно оплаты
        setAddressIsDisplayed(false);
    }

    function closePaymentModal() {
        setPaymentIsDisplayed(false)
    }

    function getQr(order: any) {
        console.log("Qr code", JSON.stringify(order))
        fetchQr(keycloak.token, {order: order})
    }

    function openCdekModal() {
        // closeOneClickOrderModal();
        fetchRegions();
        setIsOpenCdekModal(true);
    }

    function closeCdekModal() {
        setIsOpenCdekModal(false);
    }

    function getDeliveryType() {
        if (!!address && !!address['deliveryAddress']) {
            return address['deliveryAddress']['type']
        } else {
            return 'russian_post'
        }
    }

    const handleCdekSubmit = (e: any) => {
        e.preventDefault();
        const request = {
            date: new Date().toISOString(),
            tariffCode: cartCdekTariff.current?.value,
            toLocation: cartCdekPoint.current?.value,
            currency: "RUB"
        }
        setCdekInfo(request)
        calculateDelivery(request)
    };

    const style = {
        position: 'absolute' as 'absolute',
        top: '10%',
        left: '10%',
        // transform: 'translate(-50%, -50%)',
        height: '80%',
        display: 'block',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'scroll'
    };

    const paymentMethods = [
        {id: "CARD", name: "Оплата картой", description: "VISA, MasterCard, МИР", disabled: false},
        {
            id: "BANK",
            name: "Оплата по номеру счета в банке",
            description: "Банк получатель - Сбербанк",
            disabled: false
        },
        {
            id: "SBER",
            name: "Перевод на карту",
            description: "Перевод на банковскую карту Сбербанк (по номеру телефона / карты)",
            disabled: true
        },
        {id: "QIWI", name: "Оплата счета QIWI", description: "Перевод через платежную систему QIWI", disabled: true},
        {id: "OTHER", name: "Оплата при получении", description: "Наложенный платеж, курьер", disabled: true}
    ]

    useLayoutEffect(() => {
        // "This only happens ONCE.  But it still happens AFTER the initial render."
        fetchCart(keycloak.token);
        fetchAddress(keycloak.token);
    }, []);

    return (
        <>
            <Container style={{marginTop: 100}}>
                <Row>
                    <Col>
                        <h1><FaShoppingCart/> Корзина({cart.purchases.length})</h1>
                    </Col>
                </Row>

                {isLoaded && <Spinner animation="grow" variant="info"/>}

                {!isLoaded && cart.purchases.map((purchase, i) => {
                    console.log(`${i}.${JSON.stringify(purchase)}`);
                    // Return the element. Also pass key
                    return (<Row className="mb-3 p-2 bg-light shadow-sm">
                        <Col xl={1} lg={1} md={2} sm={2} className="my-auto col-6 text-center">
                            <img alt="" className="rounded m-1" width="100%" style={{maxWidth: '100px'}}
                                 src={purchase['position']['catalogEntry']['product']['s3file'] ? '/file?name=' + purchase['position']['catalogEntry']['product']['s3file'] : 'http://placehold.it/50x50'}/>
                        </Col>
                        <Col xl={4} lg={4} md={3} sm={3} className="my-auto col-6">
                            <Row>
                                <Col
                                    className="h5 border-bottom">{purchase['position']['catalogEntry']['product']['name']}</Col>
                            </Row>
                            <Row>
                                <Col
                                    className="small">Цвет: {purchase['position']['catalogEntry']['product']['color']}</Col>
                            </Row>

                        </Col>
                        <Col xl={1} lg={1} md={2} sm={2} className="my-auto text-center col-6">
                            {purchase['position']['count'] * purchase['count']} шт.
                        </Col>
                        <Col xl={1} lg={1} md={2} sm={2} className="my-auto col-6">
                            <FaMinusSquare
                                className="text-danger"
                                style={{cursor: "pointer"}}
                                onClick={() => {
                                    action(purchase['position']['id'], ACTION_REMOVE)
                                }}
                            /> {purchase['count']} <FaPlusSquare
                            className="text-success"
                            style={{cursor: "pointer"}}
                            onClick={() => {
                                action(purchase['position']['id'], ACTION_ADD)
                            }}
                        />
                        </Col>
                        <Col xl={1} lg={1} md={2} sm={2}
                             className="my-auto text-dark h4 font-weight-bold col-6 text-center">
                            {purchase['position']['sum'] * purchase['count']}&#8381;
                        </Col>
                        <Col xl={4} lg={4} md={1} sm={1} className="my-auto text-right col-12">
                            <FaTrash
                                className="text-dark"
                                style={{cursor: "pointer"}}
                                onClick={() => {
                                    action(purchase['position']['id'], ACTION_REMOVE_ALL)
                                }}
                            />
                        </Col>
                    </Row>)
                })}
                <Row className="mb-3 p-2 bg-light shadow-sm">
                    <Col className="my-auto col-12 text-right">
                        <h3>= {cart.sum}&#8381;</h3>
                    </Col>
                </Row>

                {!isLoaded && !displayAddress && cart.purchases.length > 0 &&
                (
                    <Row className="mb-3 p-2 bg-light shadow-sm">
                        <Col className="my-auto col-12 text-right">
                            <Button
                                variant={"warning"}
                                onClick={() => {
                                    showAddress()
                                }}
                            >
                                Оформить заказ
                            </Button>
                        </Col>
                    </Row>
                )
                }

                {!isLoaded && displayAddress &&
                (
                    <>
                        <Row className="mb-3 p-2 bg-light shadow-sm">
                            <Col className="my-auto col-12">
                                <h3><FaAddressCard/> Адрес доставки </h3>
                                <Tabs
                                    id="address-tab-view"
                                    activeKey={getDeliveryType()}
                                    className="mb-3"
                                >
                                    <Tab eventKey="russian_post" title="Почта России" disabled>

                                        < Row className="mb-3 p-2 bg-light shadow-sm">

                                            <Col className="my-auto col-12">

                                                {!!address && !!address['deliveryAddress'] && (<dl className="row">

                                                    <dt className="col-sm-3">Индекс</dt>
                                                    <dd className="col-sm-9">{address['deliveryAddress']['zipCode']}</dd>

                                                    <dt className="col-sm-3">Область/район</dt>
                                                    <dd className="col-sm-9">{address['deliveryAddress']['state']}</dd>

                                                    <dt className="col-sm-3">Город/Населенный пункт</dt>
                                                    <dd className="col-sm-9">{address['deliveryAddress']['city']}</dd>

                                                    <dt className="col-sm-3">Улица</dt>
                                                    <dd className="col-sm-9">{address['deliveryAddress']['street']}</dd>

                                                    <dt className="col-sm-3">Получатель</dt>
                                                    <dd className="col-sm-9">{address['deliveryAddress']['recipient']}</dd>

                                                    <dt className="col-sm-3">Способ связи</dt>
                                                    <dd className="col-sm-9">{address['deliveryAddress']['phone']}</dd>

                                                </dl>)}

                                            </Col>

                                        </Row>

                                    </Tab>

                                    <Tab eventKey="cis_post" title="Почта СНГ (CIS)" disabled>

                                        < Row className="mb-3 p-2 bg-light shadow-sm">

                                            <Col className="my-auto col-12">

                                                {!!address && !!address['deliveryAddress'] && (<dl className="row">

                                                    <dt className="col-sm-3">Страна</dt>
                                                    <dd className="col-sm-9">{address['deliveryAddress']['country']}</dd>

                                                    <dt className="col-sm-3">Полный адрес</dt>
                                                    <dd className="col-sm-9">{address['deliveryAddress']['fullAddress']}</dd>

                                                    <dt className="col-sm-3">Получатель</dt>
                                                    <dd className="col-sm-9">{address['deliveryAddress']['recipient']}</dd>

                                                    <dt className="col-sm-3">Дополнительная информация</dt>
                                                    <dd className="col-sm-9">{address['deliveryAddress']['additionalInfo']}</dd>

                                                </dl>)}

                                            </Col>

                                        </Row>

                                    </Tab>
                                    <Tab eventKey="int_post" title="Международная почта (International)" disabled>
                                    </Tab>
                                    <Tab eventKey="cdek" title="CDEK" disabled>
                                        < Row className="mb-3 p-2 bg-light shadow-sm">

                                            <Col className="my-auto col-12">

                                                {!!address && !!address['deliveryAddress'] && (<dl className="row">

                                                    <dt className="col-sm-3">Пункт назначения CDEK (номер)</dt>
                                                    <dd className="col-sm-9">{address['deliveryAddress']['toLocation']}</dd>

                                                    <dt className="col-sm-3">Код тарифа</dt>
                                                    <dd className="col-sm-9">{address['deliveryAddress']['tariffCode']}</dd>

                                                    <dt className="col-sm-3">Полный адрес</dt>
                                                    <dd className="col-sm-9">{address['deliveryAddress']['fullAddress']}</dd>

                                                    <dt className="col-sm-3">Получатель</dt>
                                                    <dd className="col-sm-9">{address['deliveryAddress']['recipient']}</dd>

                                                    <dt className="col-sm-3">Телефон</dt>
                                                    <dd className="col-sm-9">{address['deliveryAddress']['phone']}</dd>

                                                </dl>)}

                                            </Col>

                                        </Row>
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                        <Row className="mb-3 p-2 bg-light shadow-sm">
                            <Col className="my-auto col-12">
                                <h3><FaCreditCard/> Способ оплаты</h3>

                                <Form>
                                    {paymentMethods.map((method, i) => {
                                        return (<div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value={method.id}
                                                onChange={() => setPaymentMethod(method.id)}
                                                checked={paymentMethod === method.id}
                                                disabled={method.disabled}
                                            />
                                            <label className="form-check-label" htmlFor="radioCartPosition">
                                                {method.name}
                                            </label>
                                            <p className="small">{method.description}</p>
                                        </div>)
                                    })}

                                    <Button
                                        variant={"warning"}
                                        disabled={!paymentMethod}
                                        onClick={() => {
                                            confirmOrder()
                                        }}
                                    >
                                        Перейти к оплате
                                    </Button>
                                </Form>

                            </Col>
                        </Row>
                    </>
                )
                }


            </Container>

            <MuiModal
                aria-labelledby="cart-cdek-modal-title"
                aria-describedby="cart-cdek-modal-description"
                open={cdekModalIsOpen}
                onClose={closeCdekModal}
                closeAfterTransition
                BackdropComponent={MuiBackdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >

                <MuiFade in={cdekModalIsOpen}>
                    <MuiBox sx={style}>

                        <div className="container">

                            <div className="modal-header">

                                <h4>
                                    <span><FaCalculator/> <ins>Расчет стоимости доставки <img height="20"
                                                                                              src="/cdek.png"/></ins></span>
                                </h4>
                                <FaWindowClose className="float-right c-pointer mt-2" onClick={closeCdekModal}/>

                            </div>
                            <div className="row mt-3 mb-3 ml-1">
                                <div className="col-12">
                                    <Form
                                        onSubmit={handleCdekSubmit}
                                    >

                                        {regions.length > 0 && <Row>
                                            <Col md={9} xs={9} xl={9}>
                                                <Form.Switch controlId="formCdekRegion">
                                                    <Form.Label>Регион</Form.Label>
                                                    <Form.Control
                                                        ref={cartCdekRegion}
                                                        as="select"
                                                        onChange={(event => {
                                                            fetchCities(event.target.value)
                                                        })}
                                                        required>
                                                        <option value="" disabled selected>???</option>
                                                        {regions.map((r, i) => {
                                                            console.log(`${i}.${JSON.stringify(r)}`);
                                                            // Return the element. Also pass key
                                                            return (<option
                                                                value={r['region_code']}>{r['country'] + ' - ' + r['region']}</option>)
                                                        })}
                                                    </Form.Control>
                                                    <Form.Text className="text-muted">
                                                        Выберите регион доставки
                                                    </Form.Text>
                                                </Form.Switch>
                                            </Col>
                                        </Row>}

                                        {cities.length > 0 && <Row>
                                            <Col md={9} xs={9} xl={9}>
                                                <Form.Switch controlId="formCdekCity">
                                                    <Form.Label>Населенный пункт</Form.Label>
                                                    <Form.Control
                                                        ref={cartCdekCity}
                                                        as="select"
                                                        onChange={(event => {
                                                            fetchPoints(event.target.value)
                                                        })}
                                                        required>
                                                        <option value="" disabled selected>???</option>
                                                        {cities.map((c, i) => {
                                                            console.log(`${i}.${JSON.stringify(c)}`);
                                                            // Return the element. Also pass key
                                                            return (<option
                                                                value={c['code']}>{c['city']} {c['sub_region'] && ('(' + c['sub_region'] + ')')}</option>)
                                                        })}
                                                    </Form.Control>
                                                    <Form.Text className="text-muted">
                                                        Выберите населенный пункт
                                                    </Form.Text>
                                                </Form.Switch>
                                            </Col>
                                        </Row>}

                                        {points.length > 0 && <Row>
                                            <Col md={9} xs={9} xl={9}>
                                                <Form.Switch controlId="formCdekPoint">
                                                    <Form.Label>Офис CDEK</Form.Label>
                                                    <Form.Control
                                                        ref={cartCdekPoint}
                                                        as="select"
                                                        required>
                                                        <option value="" disabled selected>???</option>
                                                        {points.map((p, i) => {
                                                            console.log(`${i}.${JSON.stringify(p)}`);
                                                            // Return the element. Also pass key
                                                            return (<option
                                                                value={p['location']['city_code']}>{p['location']['address']} ({p['work_time']})</option>)
                                                        })}
                                                    </Form.Control>
                                                    <Form.Text className="text-muted">
                                                        Выберите офис CDEK
                                                    </Form.Text>
                                                </Form.Switch>
                                            </Col>
                                        </Row>}

                                        <Row>
                                            <Col md={9} xs={9} xl={9}>
                                                <Form.Switch controlId="formCdekTariff">
                                                    <Form.Label>Тариф</Form.Label>
                                                    <Form.Control
                                                        ref={cartCdekTariff}
                                                        as="select"
                                                        required>
                                                        <option value={136} selected={points.length > 0}>склад-склад
                                                        </option>
                                                        <option value={137}>склад-дверь</option>
                                                        <option value={368}>склад-постамат</option>
                                                    </Form.Control>
                                                    <Form.Text className="text-muted">
                                                        Выберите Тариф
                                                    </Form.Text>
                                                </Form.Switch>
                                            </Col>
                                        </Row>

                                        <div className="modal-footer">
                                            <MuiButton
                                                type="submit"
                                                startIcon={<FaCalculator/>}
                                                variant="contained"
                                                disabled={points.length === 0}>
                                                Расчитать
                                            </MuiButton>
                                        </div>
                                    </Form>
                                </div>
                            </div>

                            {!!calculation &&
                            <div className="row mt-3 mb-3 ml-1">
                                <div className="col-12">
                                    <p>Стоимость доставки: <b>{calculation['total_sum']}</b> {calculation['currency']}
                                    </p>
                                    <p className="small">Включая
                                        упаковку:<span> {calculation['services'][0].code} = {calculation['services'][0].sum} {calculation.currency}</span>
                                    </p>
                                    <p>Минимальное время доставки (в рабочих днях): {calculation['period_min']}</p>
                                    <p>Максимальное время доставки (в рабочих днях): {calculation['period_max']}</p>
                                </div>
                            </div>}

                        </div>

                    </MuiBox>
                </MuiFade>

            </MuiModal>


            <Modal
                isOpen={displayPayment}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closePaymentModal}
                // style={customStyles}
                contentLabel="Оплата"
            >


                <div className="container">

                    <div className="modal-header">

                        <h4>
                            <span><ins>Оплата заказа</ins></span>
                        </h4>
                        <FaWindowClose className="float-right c-pointer mt-2" onClick={closePaymentModal}/>

                    </div>
                    <div className="row mt-3 mb-3 ml-1">
                        <div className="col-12">
                            <p>
                                Номер вашего заказа <b>{!!order && order.id}</b>
                            </p>
                            {paymentMethod === 'CARD' && <ModalFooter>
                                {!payment &&
                                <p className="small">Подготавливаю платежную форму ... <Spinner animation="grow"
                                                                                                variant="info"/></p>}
                                {!!payment && <p><a href={payment.confirmationUrl}>

                                    <Button className="shadow" variant="warning">
                                        <FaYandex/> Оплатить
                                    </Button>

                                </a></p>}
                            </ModalFooter>}
                            {paymentMethod === 'BANK' && <ModalFooter>
                                {(!qr || qr.info.order !== order.id) && <span className="ml-2">
                                    <ThemeProvider theme={theme}>
                                    <MuiButton
                                        type="button"
                                        startIcon={<FaQrcode/>}
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            getQr(order.id)
                                        }}>
                                            QR-код
                                        </MuiButton>
                                    </ThemeProvider>
                            </span>}
                                {!!qr && qr.info.order === order.id && (<Row>
                                    <Col>
                                        <img className="rounded" src={'data:image/png;base64,' + qr.data}/>
                                    </Col>
                                    <Col>
            <span className="small">Данный QR-код можно отсканировать в мобильном приложении банка,
              проверено в приложениях Тинькофф и ВТБ</span>
                                    </Col>
                                    <Col>
                                        <input type="checkbox" checked={sendInvoiceToEmail}
                                               onClick={() => setSendInvoiceToEmail(!sendInvoiceToEmail)}/><span
                                        className="small ml-1">Отправить квитанцию на email</span> <br/>
                                        <MuiButton
                                            type="button"
                                            startIcon={<FaFilePdf/>}
                                            variant="outlined"
                                            onClick={() => {
                                                fetchPdf(keycloak.token, {
                                                    order: order.id,
                                                    sendToEmail: sendInvoiceToEmail
                                                })
                                                setPaymentIsDisplayed(false)
                                            }}>
                                            Скачать квитанцию
                                        </MuiButton>
                                    </Col>
                                </Row>)}
                            </ModalFooter>}
                        </div>
                    </div>

                </div>


            </Modal>
        </>
    );
});

export default Cart;
