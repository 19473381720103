import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = Keycloak({
    url: 'https://psfbs.ru/auth',
    realm: 'psfbs',
    clientId: 'backend-app'
});

export default keycloak;