import {useKeycloak} from '@react-keycloak/web';
import React from 'react';
import {Link} from 'react-router-dom';
import {Button, ButtonGroup, Container, Dropdown, Form, Nav, Navbar} from "react-bootstrap";
import {
    FaAddressCard,
    FaComment,
    FaComments,
    FaDoorClosed,
    FaDoorOpen,
    FaHome,
    FaInfoCircle,
    FaShoppingBag,
    FaShoppingCart,
    FaThLarge,
    FaUserCircle
} from "react-icons/fa";
import logo from '../ps_logo_640.png'
import Store from "../stores/CustomerStore";
import {observer} from "mobx-react";
import {useForm} from "react-hook-form";
import {useHistory} from 'react-router-dom';

const Navigation = observer((props: any) => {

    const {cart} = Store
    const {keycloak} = useKeycloak()
    const {register, getValues} = useForm();
    const history = useHistory();

    return (
        <Navbar collapseOnSelect={true} fixed="top" expand="lg" bg="dark" variant="dark" className="shadow">
            <Container>
                <Navbar.Brand href="/">

                    <img
                        src={logo}
                        width="40"
                        height="40"
                        className="d-inline-block align-content-center mr-2"
                        alt="Logo"
                    />PurpleSun
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Item>
                            <Nav.Link eventKey="1" as={Link} to={`/home`}>
                                <FaHome/> Главная
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link eventKey="2" as={Link} to={`/about`}>
                                <FaInfoCircle/> О нас
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link eventKey="3" as={Link} to={`/contacts`}>
                                <FaComment/> Контакты
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link eventKey="4" as={Link} to={`/catalog`}>
                                <FaThLarge/> Каталог
                            </Nav.Link>
                        </Nav.Item>

                        {!!keycloak.authenticated && (
                            <Nav.Item>
                                <Nav.Link eventKey="5" as={Link} to={`/cart`}>
                                    <FaShoppingCart/> Корзина{cart.purchases.length > 0 ? `(${cart.purchases.length})` : ''}
                                </Nav.Link>
                            </Nav.Item>
                        )}
                        {!keycloak.authenticated && (
                            <Nav.Item>
                                <Nav.Link eventKey="6" onClick={() => keycloak.login()}>
                                    <FaDoorClosed/>Вход / Регистрация
                                </Nav.Link>
                            </Nav.Item>
                        )}
                        {keycloak?.authenticated && (
                            <Dropdown as={ButtonGroup}>
                                <Button variant="outline-info"><FaUserCircle/></Button>
                                <Dropdown.Toggle split variant="outline-info" id="dropdown-split-basic"/>
                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey="7" as={Link} to={`/address`}>
                                        <FaAddressCard/> Адрес
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey="8" as={Link} to={`/orders`}>
                                        <FaShoppingBag/> Заказы
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey="9" as={Link} to={`/messages`}>
                                        <FaComments/> Сообщения
                                    </Dropdown.Item>

                                    <Dropdown.Divider/>

                                    <Dropdown.Item onClick={() => keycloak.logout()}>
                                        <FaDoorOpen/> Выйти
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    </Nav>
                    <Form>
                        <input {...register('phrase')} /> {/* register an input */}
                        <button
                            className="btn btn-outline-info"
                            onClick={() => {
                                const phrase = getValues("phrase");
                                if (!!phrase && phrase.length > 0) {
                                    history.push('/search?phrase=' + phrase)
                                }
                            }}
                        >Найти
                        </button>
                    </Form>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
});

export default Navigation;
