import React, {useLayoutEffect} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {observer} from "mobx-react";
import Store from "../stores/CustomerStore";
import "../css/contacts.css"

const Privacy = observer((props: any) => {

    const {fetchStaticResource, privacy} = Store

    useLayoutEffect(() => {
        // "This only happens ONCE.  But it still happens AFTER the initial render."
        fetchStaticResource("privacy")
    }, []);

    return (
        <>
            <Container style={{marginTop: 100}}>
                <Row>
                    <Col dangerouslySetInnerHTML={{__html: privacy !=='' ? privacy : ' ' }}>
                    </Col>
                </Row>
            </Container>
        </>
    );
});

export default Privacy;
