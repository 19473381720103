import React, {useLayoutEffect} from 'react';
import {Button, Card, Col, Container, Form, ModalFooter, Row, Spinner} from "react-bootstrap";
import MuiButton from '@mui/material/Button';
import MuiBackdrop from '@mui/material/Backdrop';
import MuiBox from '@mui/material/Box';
import MuiModal from '@mui/material/Modal';
import MuiFade from '@mui/material/Fade';
import MuiTypography from '@mui/material/Typography';
import {
    FaBoxes, FaCalculator,
    FaCartPlus, FaCreditCard,
    FaPlus, FaSave,
    FaShoppingBasket,
    FaShoppingCart,
    FaSquare,
    FaWindowClose,
    FaYandex
} from "react-icons/fa";
import Store from "../stores/CustomerStore";
import {observer} from "mobx-react";
import {useKeycloak} from "@react-keycloak/web";
import Modal from 'react-modal';
import {ACTION_ADD} from "../utils/const";
import StarRatingComponent from 'react-star-rating-component';
import {Link} from "react-router-dom";
import {YMInitializer} from 'react-yandex-metrika';
import {
    VKShareButton,
    VKIcon,
    OKShareButton,
    OKIcon,
    FacebookShareButton,
    FacebookIcon
} from "react-share";
import '../css/share.css'
import {Icon} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {amber, blue} from "@mui/material/colors";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";


const Item = observer((props: any) => {

    const {
        isLoaded,
        product,
        productFiles,
        productFile,
        productRating,
        setProductFile,
        cleanProductFiles,
        fetchProduct,
        fetchRating,
        voteProduct,
        price,
        cartPositions,
        fetchCartPositions,
        actionCart,
        cart,
        createOneClickOrder,
        payment,
        countries,
        fetchCountries,
        deliveryTypes,
        fetchDeliveryTypes,
        deliveries,
        fetchDeliveries,
        regions,
        cities,
        points,
        calculation,
        fetchRegions,
        fetchCities,
        fetchPoints,
        calculateDelivery,
        fetchPointInfo,
        cdekPointInfo
    } = Store
    const {url, params} = props.match
    const {keycloak} = useKeycloak()

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [oneClickOrderModalIsOpen, setIsOpenOneClickOrderModal] = React.useState(false);
    const [displayPayment, setPaymentIsDisplayed] = React.useState(false);
    const [selectedPosition, setSelectedPosition] = React.useState(false);
    const [cdekModalIsOpen, setIsOpenCdekModal] = React.useState(false);
    const [selectedDelivery, setSelectedDelivery] = React.useState('');
    const [cdekInfo, setCdekInfo] = React.useState({});

    const [key, setKey] = React.useState('russian_post');
    // Russian
    const zipCode = React.createRef<HTMLInputElement>();
    const state = React.createRef<HTMLInputElement>();
    const city = React.createRef<HTMLInputElement>();
    const street = React.createRef<HTMLInputElement>();
    const recipient = React.createRef<HTMLInputElement>();
    const phone = React.createRef<HTMLInputElement>();

    // CIS
    const cisCountry = React.createRef<HTMLSelectElement>();
    const cisFullAddress = React.createRef<HTMLInputElement>();
    const cisRecipient = React.createRef<HTMLInputElement>();
    const cisAdditionalInfo = React.createRef<HTMLInputElement>();

    // CDEK
    const cdekToLocation = React.createRef<HTMLInputElement>();
    const cdekTariffCode = React.createRef<HTMLInputElement>();
    const cdekFullAddress = React.createRef<HTMLInputElement>();
    const cdekPhone = React.createRef<HTMLInputElement>();
    const cdekRecipient = React.createRef<HTMLInputElement>();

    // Выбранный пункт доставки
    const [cdekPointCode, setCdekPointCode] = React.useState('');
    const [cdekTariffCodeValue, setCdekTariffCodeValue] = React.useState('');

    const [cartPosition, setCartPosition] = React.useState('')

    const cartPositionId = React.createRef<HTMLInputElement>();
    const email = React.createRef<HTMLInputElement>();
    const country = React.createRef<HTMLSelectElement>();
    const deliveryType = React.createRef<HTMLSelectElement>();
    const delivery = React.createRef<HTMLSelectElement>();
    const contactPhone = React.createRef<HTMLInputElement>();
    const contactName = React.createRef<HTMLInputElement>();

    const cdekRegion = React.createRef<HTMLSelectElement>();
    const cdekCity = React.createRef<HTMLSelectElement>();
    const cdekPoint = React.createRef<HTMLSelectElement>();
    const cdekTariff = React.createRef<HTMLSelectElement>();

    const theme = createTheme({
        palette: {
            primary: blue,
            secondary: amber,
        },
    });

    const handleSubmit = (e: any) => {
        e.preventDefault();
        var delivery = {};
        switch (key) {
            case 'russian_post':
                delivery = {
                    type: key,
                    zipCode: zipCode.current?.value,
                    state: state.current?.value,
                    city: city.current?.value,
                    street: street.current?.value,
                    recipient: recipient.current?.value,
                    phone: phone.current?.value
                };
                break;
            case 'cis_post':
                delivery = {
                    type: key,
                    country: cisCountry.current?.value,
                    fullAddress: cisFullAddress.current?.value,
                    recipient: cisRecipient.current?.value,
                    additionalInfo: cisAdditionalInfo.current?.value
                };
                break;
            case 'int_post':
                // TODO
                break;
            case 'cdek':
                delivery = {
                    type: key,
                    toLocation: cdekCity.current?.value,
                    tariffCode: cdekTariff.current?.value,
                    fullAddress: cdekPoint.current?.value,
                    recipient: cdekRecipient.current?.value,
                    phone: cdekPhone.current?.value,
                };
                break;
        }
        const request = {
            cartPositionId: selectedPosition,
            email: email.current?.value,
            deliveryAddress: delivery
        }

        createOneClickOrder(request)
        closeOneClickOrderModal()
        setPaymentIsDisplayed(true)
    };

    function handleCdekSubmit() {
        let cityCode = cdekCity.current?.value;
        let tariff = cdekTariff.current?.value;
        setCdekTariffCodeValue('' + tariff);
        const request = {
            date: new Date().toISOString(),
            tariffCode: tariff,
            toLocation: cityCode,
            currency: "RUB"
        };
        console.log("cdekPointCode: " + cdekPointCode)
        calculateDelivery(request)
        let city = cdekCity.current?.value;
        fetchPointInfo(city,cityCode)
    }

    const style = {
        position: 'absolute' as 'absolute',
        top: '10%',
        left: '10%',
        // transform: 'translate(-50%, -50%)',
        height: '80%',
        display: 'block',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'scroll'
    };

    const redFont = {
        'color': 'IndianRed',
        'font-weight': 'bold'
    };

    const yooKassaIcon = (
        <Icon>
            <img alt="yookassa" src="/yookassa.svg"/>
        </Icon>
    );

    function openCartPositionsModal() {
        setIsOpen(true);
    }

    function closeCartPositionsModal() {
        setIsOpen(false);
    }

    function openOneClickOrderModal() {
        setIsOpenOneClickOrderModal(true);
        fetchCountries()
    }

    function closeOneClickOrderModal() {
        setIsOpenOneClickOrderModal(false);
    }

    function openCdekModal() {
        // closeOneClickOrderModal();
        fetchRegions();
        setIsOpenCdekModal(true);
    }

    function closeCdekModal() {
        setIsOpenCdekModal(false);
    }

    function closePaymentModal() {
        setPaymentIsDisplayed(false)
    }

    function action(position: String, action: String) {
        console.log(`${action} position: id=${position}`);
        actionCart(position, action, keycloak.token);
        closeCartPositionsModal();
    }

    useLayoutEffect(() => {
        // "This only happens ONCE.  But it still happens AFTER the initial render."
        cleanProductFiles();
        fetchProduct(params.catId, params.itemId, {});
        fetchRating({productId: params.itemId});
        fetchCartPositions(params.catId, params.itemId, {});
    }, []);

    return (
        <>
            <YMInitializer accounts={[85862571]} options={{webvisor: true}}/>

            <Container style={{marginTop: 100}}>
                <Row>
                    <Col className="my-auto">
                        <h1><FaSquare/> {product && product['category']['name']}</h1>
                    </Col>
                </Row>

                {isLoaded && <Spinner animation="border"/>}

                {product &&
                <Row>
                    <Col xl={6} lg={6} md={12} sm={12} className="mb-5">
                        <Card className="shadow">
                            <Card.Header>
                                <Row>
                                    <Col><h5>{product['product']['name']}</h5></Col>
                                    <Col className="text-right">
                                        <FacebookShareButton
                                            url={"https://psfbs.ru" + url}
                                            quote={product['product']['name']}
                                            className="share-button"
                                        >
                                            <FacebookIcon size={32} round/>
                                        </FacebookShareButton>

                                        <VKShareButton
                                            url={"https://psfbs.ru" + url}
                                            image={'https://psfbs.ru/file?name=' + productFile}
                                            className="share-button"
                                        >
                                            <VKIcon size={32} round/>
                                        </VKShareButton>

                                        <OKShareButton
                                            url={"https://psfbs.ru" + url}
                                            image={'https://psfbs.ru/file?name=' + productFile}
                                            className="share-button"
                                        >
                                            <OKIcon size={32} round/>
                                        </OKShareButton>
                                    </Col>
                                </Row>

                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col sm={8}>
                                        <img alt="" className="rounded" width="100%"
                                             src={productFile ? '/file?name=' + productFile : 'http://placehold.it/200x200'}/>
                                    </Col>
                                    <Col sm={4}>
                                        {productFiles.map((file, i) => {
                                            console.log(`Load file: ${file}`);
                                            // Return the element. Also pass key
                                            return (<img alt="" className="rounded m-1" width="50"
                                                         src={'/file?name=' + file}
                                                         onClick={() => {
                                                             setProductFile(file)
                                                         }
                                                         }/>)
                                        })}
                                    </Col>
                                </Row>

                            </Card.Body>
                            <Card.Footer className="text-sm-right">

                                {(!!keycloak.authenticated && cartPositions.length > 0 && (
                                    <MuiButton
                                        startIcon={<FaShoppingCart/>}
                                        variant="contained"
                                        onClick={() => {
                                            openCartPositionsModal()
                                        }}
                                    >
                                        Купить
                                    </MuiButton>
                                )) || (cartPositions.length > 0 && (
                                    <MuiButton
                                        startIcon={<FaShoppingCart/>}
                                        variant="contained"
                                        onClick={() => {
                                            openOneClickOrderModal()
                                        }}>
                                        Купить в один клик
                                    </MuiButton>
                                ))}

                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} className="mb-5">
                        <Card className="shadow">
                            <Card.Header>
                                <span style={{color: 'darkred'}} className="h3 mr-2">{product.price}&#8381;</span>
                                {(product.discount.percent > 0) && <span style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>{product.product.price}&#8381;</span>}
                            </Card.Header>
                            <Card.Body>
                                <Row className="mb-3">
                                    <Col className="col-auto">
                                        <StarRatingComponent
                                            editing={!!keycloak.authenticated}
                                            name="productRating"
                                            starCount={5}
                                            value={productRating.average}
                                            onStarClick={(value) => {
                                                console.log("Click on rate star")
                                                voteProduct(keycloak.token, {
                                                    productId: productRating.productId,
                                                    value: value
                                                })
                                            }}
                                        />
                                    </Col>
                                    <Col className="col-auto small">Оценка товара: {productRating.average} из 5</Col>
                                    <Col className="col-auto small">Голосов: {productRating.votes}</Col>
                                </Row>

                                <dl className="row">

                                    <dt className="col-sm-3">В наличии:</dt>
                                    <dd className="col-sm-9">{cartPositions.length > 0 ? 'Да' : 'Нет'}</dd>

                                    <dt className="col-sm-3">Цвет</dt>
                                    <dd className="col-sm-9">{product['product']['color']}</dd>

                                    <dt className="col-sm-3 text-truncate">Описание</dt>
                                    <dd className="col-sm-9">{product['product']['description']}
                                    </dd>

                                    <dt className="col-sm-3">Артикул:</dt>
                                    <dd className="col-sm-9">{product['product']['id']}</dd>

                                </dl>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>}
            </Container>

            <Modal
                isOpen={modalIsOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeCartPositionsModal}
                // style={customStyles}
                contentLabel="Доступная скидка"
            >


                <div className="container">

                    <div className="modal-header">

                        <h4>
                            <span><FaCartPlus/> <ins>Выбрать количество</ins></span>
                        </h4>
                        <FaWindowClose className="float-right c-pointer mt-2" onClick={closeCartPositionsModal}/>

                    </div>
                    <div className="row mt-3 mb-3 ml-1">
                        <div className="col-12">
                            <Form
                                onSubmit={() => {
                                    action(cartPosition, ACTION_ADD)
                                }}
                            >


                                {cartPositions.map((position, i) => {
                                    console.log(`${i}.${JSON.stringify(position)}`);
                                    // Return the element. Also pass key
                                    return (<div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            value={position['id']}
                                            checked={cartPosition === position['id']}
                                            onChange={() => setCartPosition(position['id'])}
                                        />
                                        <label className="form-check-label" htmlFor="radioCartPosition">
                                            {position['count']} шт. за {position['sum']}&#8381;
                                        </label>
                                    </div>)
                                })}


                                <div className="modal-footer">
                                    <button className="btn btn-primary">
                                        <FaPlus/> Купить
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>

                </div>


            </Modal>

            <MuiModal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={oneClickOrderModalIsOpen}
                onClose={closeOneClickOrderModal}
                closeAfterTransition
                BackdropComponent={MuiBackdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >

                <MuiFade in={oneClickOrderModalIsOpen}>
                    <MuiBox sx={style}>
                        {/*<MuiTypography id="transition-modal-title" variant="h6" component="h2">*/}
                        {/*    Купить в один клик*/}
                        {/*</MuiTypography>*/}
                        {/*<MuiTypography id="transition-modal-description" sx={{ mt: 2 }}>*/}
                        {/*    Для заказа товара необходимо заполнить все поля формы*/}
                        {/*</MuiTypography>*/}

                        <div className="container">

                            <div className="modal-header">

                                <h4>
                                    <span><FaShoppingBasket/> <ins>Купить {product && product['product']['name']}</ins></span>
                                </h4>
                                <FaWindowClose className="float-right c-pointer mt-2"
                                               onClick={closeOneClickOrderModal}/>

                            </div>

                            <div className="row m-2">
                                <div className="col">
                                    <img alt="" width="50%" className="rounded"
                                         src={productFile ? '/file?name=' + productFile : 'http://placehold.it/200x200'}/>
                                </div>
                            </div>
                            <div className="row mt-3 mb-3 ml-1">
                                <div className="col-12">
                                    {!selectedPosition && (
                                        <p className="small" style={{color: 'red'}}>Выберите количество для заказа</p>)}
                                    <Form
                                        onSubmit={handleSubmit}
                                    >

                                        {cartPositions.map((position, i) => {
                                            console.log(`${i}.${JSON.stringify(position)}`);
                                            // Return the element. Also pass key
                                            return (<div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    ref={cartPositionId}
                                                    value={position['id']}
                                                    checked={selectedPosition === position['id']}
                                                    onClick={() => setSelectedPosition(position['id'])}
                                                    required
                                                />
                                                <label className="form-check-label" htmlFor="radioCartPosition">
                                                    {position['count']} шт. за {position['sum']}&#8381;
                                                </label>
                                            </div>)
                                        })}

                                        <Row>
                                            <Col md={9} xs={9} xl={9}>
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type="text"
                                                                  ref={email}
                                                                  required/>
                                                    <Form.Text className="text-muted">
                                                        Введите Email для получения информации о заказе.
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                        </Row>


                                        <Tabs
                                            id="address-tab-edit"
                                            activeKey={key}
                                            onSelect={(k: any) => {
                                                setKey(k)
                                                if(k === 'cdek') {
                                                    fetchRegions();
                                                }
                                            }}
                                            className="mb-3"
                                        >
                                            <Tab eventKey="russian_post" title="Почта России">

                                                <div className="row mt-3 mb-3 ml-1">
                                                    <div className="col-12">
                                                        <Row>
                                                            <Col md={9} xs={9} xl={9}>
                                                                <Form.Group controlId="formBasicZip">
                                                                    <Form.Label>Почтовый индекс</Form.Label>
                                                                    <Form.Control type="text" placeholder=""
                                                                                  ref={zipCode}
                                                                                  required={key === 'russian_post'}/>
                                                                    <Form.Text className="text-muted">
                                                                        Введите почтовый индекс доставки. Пример: 123456
                                                                    </Form.Text>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={9} xs={9} xl={9}>
                                                                <Form.Group controlId="formBasicState">
                                                                    <Form.Label>Область</Form.Label>
                                                                    <Form.Control type="text" placeholder=""
                                                                                  ref={state}
                                                                                  required={key === 'russian_post'}/>
                                                                    <Form.Text className="text-muted">
                                                                        Введите область доставки. Пример: Московская
                                                                        область
                                                                    </Form.Text>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={9} xs={9} xl={9}>
                                                                <Form.Group controlId="formBasicCity">
                                                                    <Form.Label>Город / населенный пункт</Form.Label>
                                                                    <Form.Control type="text" placeholder=""
                                                                                  ref={city}
                                                                                  required={key === 'russian_post'}/>
                                                                    <Form.Text className="text-muted">
                                                                        Введите город доставки. Пример: г.Королёв
                                                                    </Form.Text>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={9} xs={9} xl={9}>
                                                                <Form.Group controlId="formBasicStreet">
                                                                    <Form.Label>Улица, дом, квартира</Form.Label>
                                                                    <Form.Control type="text" placeholder=""
                                                                                  ref={street}
                                                                                  required={key === 'russian_post'}/>
                                                                    <Form.Text className="text-muted">
                                                                        Введите адрес доставки. Пример: ул.Речная, д.9А,
                                                                        кв.123
                                                                    </Form.Text>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={9} xs={9} xl={9}>
                                                                <Form.Group controlId="formBasicRecipient">
                                                                    <Form.Label>ФИО получателя</Form.Label>
                                                                    <Form.Control type="text" placeholder=""
                                                                                  ref={recipient}
                                                                                  required={key === 'russian_post'}/>
                                                                    <Form.Text className="text-muted">
                                                                        Введите полное имя получателя.
                                                                    </Form.Text>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={9} xs={9} xl={9}>
                                                                <Form.Group controlId="formBasicRecipient">
                                                                    <Form.Label>Способ связи</Form.Label>
                                                                    <Form.Control type="text" placeholder=""
                                                                                  ref={phone}
                                                                                  required={key === 'russian_post'}/>
                                                                    <Form.Text className="text-muted">
                                                                        Для получения уведомлений об оплате и доставке<br/>(Например: телефон, whatsapp, telegram, viber, skype...)
                                                                    </Form.Text>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>

                                            </Tab>

                                            <Tab eventKey="cis_post" title="Почта СНГ (CIS)">

                                                <div className="row mt-3 mb-3 ml-1">
                                                    <div className="col-12">

                                                        <Row>
                                                            <Col md={9} xs={9} xl={9}>
                                                                <Form.Switch controlId="formBasicCisCountry">
                                                                    <Form.Label>Страна</Form.Label>
                                                                    <Form.Control
                                                                        ref={cisCountry}
                                                                        as="select"
                                                                        required={key === 'cis_post'}>
                                                                        {countries.map((c, i) => {
                                                                            console.log(`${i}.${JSON.stringify(c)}`);
                                                                            // Return the element. Also pass key
                                                                            return (<option
                                                                                value={c['name']}>{c['name']}</option>)
                                                                        })}
                                                                    </Form.Control>
                                                                    <Form.Text className="text-muted">
                                                                        Выберите страну доставки из списка
                                                                    </Form.Text>
                                                                </Form.Switch>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={9} xs={9} xl={9}>
                                                                <Form.Group controlId="formBasicCisFullAddress">
                                                                    <Form.Label>Полный адрес</Form.Label>
                                                                    <Form.Control type="text" placeholder=""
                                                                                  ref={cisFullAddress}
                                                                                  required={key === 'cis_post'}/>
                                                                    <Form.Text className="text-muted">
                                                                        Введите полный адрес доставки
                                                                    </Form.Text>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={9} xs={9} xl={9}>
                                                                <Form.Group controlId="formBasicCisRecipient">
                                                                    <Form.Label>Имя получателя</Form.Label>
                                                                    <Form.Control type="text" placeholder=""
                                                                                  ref={cisRecipient}
                                                                                  required={key === 'cis_post'}/>
                                                                    <Form.Text className="text-muted">
                                                                        Введите полное имя получателя
                                                                    </Form.Text>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={9} xs={9} xl={9}>
                                                                <Form.Group controlId="formBasicCisAdditionalInfo">
                                                                    <Form.Label>Дополнительная информация</Form.Label>
                                                                    <Form.Control type="text" placeholder=""
                                                                                  ref={cisAdditionalInfo}
                                                                                  required={key === 'cis_post'}/>
                                                                    <Form.Text className="text-muted">
                                                                        Введите дополнительную информацию о доставке <br/>(Например, доступный способ связи: телефон, whatsapp, telegram, viber, skype...)
                                                                    </Form.Text>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                </div>


                                            </Tab>

                                            <Tab
                                                eventKey="cdek"
                                                title="CDEK">

                                                <div className="row mt-3 mb-3 ml-1">
                                                    <div className="col-12">
                                                        <Form>

                                                            {regions.length === 0 && <Spinner animation="grow" variant="info"/>}

                                                            {regions.length > 0 && <Row>
                                                                <Col md={9} xs={9} xl={9}>
                                                                    <Form.Switch controlId="formCdekRegion">
                                                                        <Form.Label>Регион</Form.Label>
                                                                        <Form.Control
                                                                            ref={cdekRegion}
                                                                            as="select"
                                                                            onChange={(event => {
                                                                                fetchCities(event.target.value)
                                                                            })}
                                                                            required={key === 'cdek'}>
                                                                            <option value="" disabled selected>???</option>
                                                                            {regions.map((r, i) => {
                                                                                console.log(`${i}.${JSON.stringify(r)}`);
                                                                                // Return the element. Also pass key
                                                                                return (<option
                                                                                    value={r['region_code']}>{r['country'] + ' - ' + r['region']}</option>)
                                                                            })}
                                                                        </Form.Control>
                                                                        <Form.Text className="text-muted">
                                                                            Выберите регион доставки
                                                                        </Form.Text>
                                                                    </Form.Switch>
                                                                </Col>
                                                            </Row>}

                                                            {cities.length > 0 && <Row>
                                                                <Col md={9} xs={9} xl={9}>
                                                                    <Form.Switch controlId="formCdekCity">
                                                                        <Form.Label>Населенный пункт</Form.Label>
                                                                        <Form.Control
                                                                            ref={cdekCity}
                                                                            as="select"
                                                                            onChange={(event => {
                                                                                fetchPoints(event.target.value)
                                                                            })}
                                                                            required={key === 'cdek'}>
                                                                            <option value="" disabled selected>???</option>
                                                                            {cities.map((c, i) => {
                                                                                console.log(`${i}.${JSON.stringify(c)}`);
                                                                                // Return the element. Also pass key
                                                                                return (<option
                                                                                    value={c['code']}>{c['city']} {c['sub_region'] && ('(' + c['sub_region'] + ')')}</option>)
                                                                            })}
                                                                        </Form.Control>
                                                                        <Form.Text className="text-muted">
                                                                            Выберите населенный пункт
                                                                        </Form.Text>
                                                                    </Form.Switch>
                                                                </Col>
                                                            </Row>}

                                                            {points.length > 0 && <Row>
                                                                <Col md={9} xs={9} xl={9}>
                                                                    <Form.Switch controlId="formCdekPoint">
                                                                        <Form.Label>Офис CDEK</Form.Label>
                                                                        <Form.Control
                                                                            ref={cdekPoint}
                                                                            as="select"
                                                                            required={key === 'cdek'}>
                                                                            <option value="" disabled selected>???</option>
                                                                            {points.map((p, i) => {
                                                                                console.log(`${i}.${JSON.stringify(p)}`);
                                                                                // Return the element. Also pass key
                                                                                return (<option
                                                                                    value={p['location']['address_full'] + ` (${p['code']})`}>{p['location']['address']} ({p['work_time']})</option>)
                                                                            })}
                                                                        </Form.Control>
                                                                        <Form.Text className="text-muted">
                                                                            Выберите офис CDEK
                                                                        </Form.Text>
                                                                    </Form.Switch>
                                                                </Col>
                                                            </Row>}

                                                            <Row>
                                                                <Col md={9} xs={9} xl={9}>
                                                                    <Form.Switch controlId="formCdekTariff">
                                                                        <Form.Label>Тариф</Form.Label>
                                                                        <Form.Control
                                                                            ref={cdekTariff}
                                                                            as="select"
                                                                            required={key === 'cdek'}>
                                                                            <option value={136} selected={points.length > 0}>склад-склад
                                                                            </option>
                                                                            {/*<option value={137}>склад-дверь</option>*/}
                                                                            <option value={368}>склад-постамат</option>
                                                                        </Form.Control>
                                                                        <Form.Text className="text-muted">
                                                                            Выберите тариф
                                                                        </Form.Text>
                                                                    </Form.Switch>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col md={9} xs={9} xl={9}>
                                                                    <Form.Group controlId="formCdekPhone">
                                                                        <Form.Label>Телефон получателя</Form.Label>
                                                                        <Form.Control type="text"
                                                                                      ref={cdekPhone}
                                                                                      required={key === 'cdek'}/>
                                                                        <Form.Text className="text-muted">
                                                                            Введите телефон
                                                                        </Form.Text>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col md={9} xs={9} xl={9}>
                                                                    <Form.Group controlId="formCdekRecipient">
                                                                        <Form.Label>ФИО получателя</Form.Label>
                                                                        <Form.Control type="text"
                                                                                      ref={cdekRecipient}
                                                                                      required={key === 'cdek'}/>
                                                                        <Form.Text className="text-muted">
                                                                            Введите полное имя получателя
                                                                        </Form.Text>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>

                                                            <div className="modal-footer">
                                                                <MuiButton
                                                                    onClick={handleCdekSubmit}
                                                                    startIcon={<FaCalculator/>}
                                                                    variant="contained"
                                                                    disabled={points.length === 0}>
                                                                    Расчитать
                                                                </MuiButton>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </div>

                                                {!!calculation &&
                                                <div className="row mt-3 mb-3 ml-1">
                                                    <div className="col-12">
                                                        <p>Стоимость доставки: <b>{calculation['total_sum']}</b> {calculation['currency']}</p>
                                                        <p className="small">Включая
                                                            упаковку:<span> {calculation['services'][0].code} = {calculation['services'][0].sum} {calculation.currency}</span>
                                                        </p>
                                                        <p>Минимальное время доставки (в рабочих днях): {calculation['period_min']}</p>
                                                        <p>Максимальное время доставки (в рабочих днях): {calculation['period_max']}</p>
                                                    </div>
                                                </div>}

                                            </Tab>

                                            <Tab
                                                disabled={true}
                                                eventKey="int_post"
                                                title="По всему миру">


                                            </Tab>
                                        </Tabs>


                                        <Row>
                                            <Col md={9} xs={9} xl={9}>
                                                <Form.Group controlId="formBasicConfirmProcessPersonalData">
                                                    <Form.Check type="checkbox"
                                                                label="Даю согласие на обработку персональных данных"
                                                                required/>
                                                </Form.Group>
                                                <Link to={'/privacy'}>
                                                    Политика конфиденциальности
                                                </Link>
                                            </Col>
                                        </Row>

                                        <div className="modal-footer">
                                            <MuiButton
                                                type="submit"
                                                startIcon={<FaPlus/>}
                                                variant="contained"
                                                disabled={!selectedPosition}>
                                                Купить
                                            </MuiButton>
                                        </div>
                                    </Form>
                                </div>
                            </div>

                        </div>

                    </MuiBox>
                </MuiFade>

            </MuiModal>

            <Modal
                isOpen={displayPayment}
                onRequestClose={closePaymentModal}
                contentLabel="Оплата"
            >


                <div className="container">

                    <div className="modal-header">

                        <h4>
                            <span><ins>Оплата заказа</ins></span>
                        </h4>
                        <FaWindowClose className="float-right c-pointer mt-2" onClick={closePaymentModal}/>

                    </div>
                    <div className="row mt-3 mb-3 ml-1">
                        <div className="col-12">
                            <p>
                                {!!payment && (`Номер вашего заказа: ${payment.orderId}`)}
                            </p>
                            <ModalFooter>
                                {!payment &&
                                <p className="small">Подготавливаю платежную форму ... <Spinner animation="grow"
                                                                                                variant="info"/></p>}
                                {!!payment && <p>
                                    <span><img className="mr-2" height="30px" alt="yookassa"
                                               src="/yookassa.svg"/></span>
                                    <ThemeProvider theme={theme}>
                                        <MuiButton
                                            href={payment.confirmationUrl}
                                            variant="contained"
                                            startIcon={<FaCreditCard/>}
                                            color="secondary">
                                            Оплатить
                                        </MuiButton>
                                    </ThemeProvider>
                                </p>}
                            </ModalFooter>
                        </div>
                    </div>

                </div>


            </Modal>


            <MuiModal
                aria-labelledby="cdek-modal-title"
                aria-describedby="cdek-modal-description"
                open={cdekModalIsOpen}
                onClose={closeCdekModal}
                closeAfterTransition
                BackdropComponent={MuiBackdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >

                <MuiFade in={cdekModalIsOpen}>
                    <MuiBox sx={style}>

                        <div className="container">

                            <div className="modal-header">

                                <h4>
                                    <span><FaCalculator/> <ins>Расчет стоимости доставки <img height="20"
                                                                                              src="/cdek.png"/></ins></span>
                                </h4>
                                <FaWindowClose className="float-right c-pointer mt-2" onClick={closeCdekModal}/>

                            </div>
                            <div className="row mt-3 mb-3 ml-1">
                                <div className="col-12">
                                    <Form
                                        onSubmit={handleCdekSubmit}
                                    >

                                        {regions.length > 0 && <Row>
                                            <Col md={9} xs={9} xl={9}>
                                                <Form.Switch controlId="formCdekRegion">
                                                    <Form.Label>Регион</Form.Label>
                                                    <Form.Control
                                                        ref={cdekRegion}
                                                        as="select"
                                                        onChange={(event => {
                                                            fetchCities(event.target.value)
                                                        })}
                                                        required>
                                                        <option value="" disabled selected>???</option>
                                                        {regions.map((r, i) => {
                                                            console.log(`${i}.${JSON.stringify(r)}`);
                                                            // Return the element. Also pass key
                                                            return (<option
                                                                value={r['region_code']}>{r['country'] + ' - ' + r['region']}</option>)
                                                        })}
                                                    </Form.Control>
                                                    <Form.Text className="text-muted">
                                                        Выберите регион доставки
                                                    </Form.Text>
                                                </Form.Switch>
                                            </Col>
                                        </Row>}

                                        {cities.length > 0 && <Row>
                                            <Col md={9} xs={9} xl={9}>
                                                <Form.Switch controlId="formCdekCity">
                                                    <Form.Label>Населенный пункт</Form.Label>
                                                    <Form.Control
                                                        ref={cdekCity}
                                                        as="select"
                                                        onChange={(event => {
                                                            fetchPoints(event.target.value)
                                                        })}
                                                        required>
                                                        <option value="" disabled selected>???</option>
                                                        {cities.map((c, i) => {
                                                            console.log(`${i}.${JSON.stringify(c)}`);
                                                            // Return the element. Also pass key
                                                            return (<option
                                                                value={c['code']}>{c['city']} {c['sub_region'] && ('(' + c['sub_region'] + ')')}</option>)
                                                        })}
                                                    </Form.Control>
                                                    <Form.Text className="text-muted">
                                                        Выберите населенный пункт
                                                    </Form.Text>
                                                </Form.Switch>
                                            </Col>
                                        </Row>}

                                        {points.length > 0 && <Row>
                                            <Col md={9} xs={9} xl={9}>
                                                <Form.Switch controlId="formCdekPoint">
                                                    <Form.Label>Офис CDEK</Form.Label>
                                                    <Form.Control
                                                        ref={cdekPoint}
                                                        as="select"
                                                        required>
                                                        <option value="" disabled selected>???</option>
                                                        {points.map((p, i) => {
                                                            console.log(`${i}.${JSON.stringify(p)}`);
                                                            // Return the element. Also pass key
                                                            return (<option
                                                                value={p['location']['city_code']}>{p['location']['address']} ({p['work_time']})</option>)
                                                        })}
                                                    </Form.Control>
                                                    <Form.Text className="text-muted">
                                                        Выберите офис CDEK
                                                    </Form.Text>
                                                </Form.Switch>
                                            </Col>
                                        </Row>}

                                        <Row>
                                            <Col md={9} xs={9} xl={9}>
                                                <Form.Switch controlId="formCdekTariff">
                                                    <Form.Label>Тариф</Form.Label>
                                                    <Form.Control
                                                        ref={cdekTariff}
                                                        as="select"
                                                        required>
                                                        <option value={136} selected={points.length > 0}>склад-склад
                                                        </option>
                                                        <option value={137}>склад-дверь</option>
                                                        <option value={368}>склад-постамат</option>
                                                    </Form.Control>
                                                    <Form.Text className="text-muted">
                                                        Выберите Тариф
                                                    </Form.Text>
                                                </Form.Switch>
                                            </Col>
                                        </Row>

                                        <div className="modal-footer">
                                            <MuiButton
                                                type="submit"
                                                startIcon={<FaCalculator/>}
                                                variant="contained"
                                                disabled={points.length === 0}>
                                                Расчитать
                                            </MuiButton>
                                        </div>
                                    </Form>
                                </div>
                            </div>

                            {!!calculation &&
                            <div className="row mt-3 mb-3 ml-1">
                                <div className="col-12">
                                    <p>Стоимость доставки: <b>{calculation['total_sum']}</b> {calculation['currency']}
                                    </p>
                                    <p className="small">Включая
                                        упаковку:<span> {calculation['services'][0].code} = {calculation['services'][0].sum} {calculation.currency}</span>
                                    </p>
                                    <p>Минимальное время доставки (в рабочих днях): {calculation['period_min']}</p>
                                    <p>Максимальное время доставки (в рабочих днях): {calculation['period_max']}</p>
                                </div>
                            </div>}

                        </div>

                    </MuiBox>
                </MuiFade>

            </MuiModal>


        </>
    );
});

export default Item;
