import React from 'react';
import {Button, Card, Col, Container, Form, ModalFooter, Row, Spinner} from "react-bootstrap";
import qr from '../assets/qr-code.svg'
import pixel from '../assets/pixel_xl_3d.png'

const MobileApp = () => {
    return (
        <Container fluid="lg" style={{marginTop: 100}}>


            <Row className="shadow rounded m-1">
                <Col className="m-5 text-center">
                    <h5>Мобильное приложение</h5>
                    <h2>PurpleSun fingerboards</h2>
                    <a href="https://psfbs.ru/app" target="_blank"><img src={qr} style={{width:250, height:250}}/></a>
                </Col>
                <Col className="mt-5">
                    <img src={pixel} style={{width:400, height:400}}/>
                </Col>
            </Row>


        </Container>
    );
};

export default MobileApp;
