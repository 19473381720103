import React, {useLayoutEffect} from 'react';
import {Card, Col, Container, Row, Spinner} from "react-bootstrap";
import {Link} from 'react-router-dom';
import {FaThLarge} from "react-icons/fa";
import {observer} from "mobx-react";
import Store from "../stores/CustomerStore";

const Category = observer((props: any) => {

    const {isLoaded, products, fetchProducts} = Store
    const {url, params} = props.match

    function price(product:any) {
        return (100 - product.discount.percent) * product.product.price / 100
    }

    useLayoutEffect(() => {
        // "This only happens ONCE.  But it still happens AFTER the initial render."
        fetchProducts(params.catId, {size: 100, sort: "id,desc"});
    }, []);

    return (
        <>
            <Container style={{marginTop: 100}}>
                <Row>
                    <Col className="my-auto">
                        <h1><FaThLarge/> {products.length && products[0]['category']['name']}</h1>
                    </Col>
                </Row>

                {isLoaded && <Spinner animation="border"/>}

                <Row className="justify-content-center mb-5">

                    {products.map((product, i) => {
                        console.log(`${i}.${JSON.stringify(product)}`);
                        // Return the element. Also pass key
                        return (<Col xl={4} lg={6} md={6} sm={6} className="mb-5">
                        <Card className="shadow">

                            <Link to={`${url}/item/${product['product']['id']}`}>
                                <Card.Img variant="top" src={product['product']['s3file'] ? '/file?name=' + product['product']['s3file'] : 'http://placehold.it/200x200'}/>
                            </Link>

                            <Card.Body>
                                <Card.Title className="text-center"><h2>{product['product']['name']}</h2></Card.Title>
                                <Card.Text>
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <Row>
                                    <Col className="h3 text-center text-danger">
                                        {price(product)}&#8381;
                                    </Col>
                                </Row>
                            </Card.Footer>
                        </Card>
                    </Col>)
                    })}

                </Row>

            </Container>


        </>
    );
});

export default Category;
